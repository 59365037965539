import { CheckCircleFilled, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Image, Popconfirm, Select, Spin, Table } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ADMIN, GET, POST } from '../../../../utils/apis'
import { allAdminData, assignMessage, checkSelectOption, convertTitle, errorMessage, stringLimiter, successNotification } from '../../../../utils/helpers'
import AddProducts from './AddProduct'
import AddReservation from './AddReservation'
import ReservationSetting from './ReservationSetting'
const { Option } = Select


const AdminProducts = (props) => {
    const { user, history, adminActions, language, selectedLanguage } = props
    const buUsers = useSelector(state => state?.adminReducer?.buUsers || [])
    const [loading, setLoading] = useState(false)
    const [approveloading, setApproveloading] = useState({})
    const [showForm, setShowForm] = useState(false)
    const [showReservationForm, setReservationForm] = useState(false)
    const [reservationSetting, setReservationsetting] = useState(false)
    const [reservationData, setReservationData] = useState(null)
    const [edit, setEdit] = useState(false)
    const [allProduct, setAllProduct] = useState([])
    const [spin, setSpin] = useState({})
    const [selectedUser, setSelectedUser] = useState(buUsers?.[0]?._id || null)
    const [productDetail, setProductDetail] = useState({})

    const isEnglish = language?.language === 'english'
    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };
    const getProducts = () => {
        setLoading(true)
        axios.get(`${GET?.GET_BU_PRODUCTS}/${selectedUser}`)
            .then((res) => {
                const { data } = res
                setAllProduct(data?.data || [])
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }
    const deleteProduct = (_id) => {
        setSpin({ [_id]: true })
        let deleteProduct = {
            _id: _id,
            userId: user?._id
        }
        axios.post(`${POST?.DELETE_PRODUCT}`, deleteProduct)
            .then((res) => {
                const { data } = res
                setSpin({})
                getProducts()
                if (data?.success) {
                    successNotification(selectedLanguage?.SuccessfullyDeleted)
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }

    useEffect(() => {
        allAdminData?.getBusinessUser(adminActions, user?._id)
    }, [])
    useEffect(() => {
        getProducts()
    }, [selectedUser])

    const gotoReservationSetting = (data) => {
        setReservationData(data?.reservation)
        setReservationsetting(true)
    }

    const approveProduct = (_id) => {
        setApproveloading({ [_id]: true })
        let obj = {
            _id,
        }
        axios.post(POST?.APPROVE_PRODUCT, obj)
            .then((res) => {
                const { data } = res
                setApproveloading({})
                getProducts()
                if (data?.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setApproveloading({})
                console.log('e', e)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }
    const activateProduct = (_id, activate) => {
        setApproveloading({ [_id]: true })
        let obj = {
            _id,
            activate
        }
        axios.post(ADMIN?.ACTIVATE_PRODUCT, obj)
            .then((res) => {
                const { data } = res
                setApproveloading({})
                getProducts()
                if (data?.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setApproveloading({})
                console.log('e', e)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    let columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.ProductImage}</p>,
            key: 'image',
            align: 'center',
            render: (e) =>
                <Image src={e?.imageUrl} style={{ marginRight: 10, width: 'auto', height: 60 }} shape='square' />
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Name}</p>,
            dataIndex: 'name',
            showSorterTooltip: false,
            sorter: (a, b) => a?.name?.length - b?.name?.length,
            render: (e) => <>{stringLimiter(e, 14)}</>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Description}</p>,
            dataIndex: 'description',
            showSorterTooltip: false,
            sorter: (a, b) => a?.description?.length - b?.description?.length,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Total}</p>,
            dataIndex: ['sold'],
            key: 'sold',
            align: 'center',
            showSorterTooltip: false,
            sorter: (a, b) => a?.sold - b?.sold,
            render: (e) => e || 0
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.label_promote}</p>,
            dataIndex: 'promote',
            showSorterTooltip: false,
            sorter: (a, b) => a?.promote - b?.promote,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? selectedLanguage?.True : selectedLanguage?.False}</p>,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Created}</p>,
            dataIndex: 'created',
            render: (e) => moment(e).format('YYYY-MM-DD')
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Active}</p>,
            width: 115,
            align: 'center',
            render: (e) =>
                <>
                    {approveloading[e?._id] ? <Spin /> :
                        e?.activate ?
                            <Button color='' onClick={() => activateProduct(e?._id, !e?.activate)}>{selectedLanguage?.Deactivate}</Button> :
                            <Button onClick={() => activateProduct(e?._id, !e?.activate)}>{selectedLanguage?.Activate}</Button>
                    }
                </>
            ,
            showSorterTooltip: false,
            sorter: (a, b) => a?.activate - b?.activate,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Status}</p>,
            width: 115,
            align: 'center',
            render: (e) => <> {e?.isApproved ? <CheckCircleFilled style={{ color: 'green' }} /> :
                <>{approveloading[e?._id] ? <Spin /> :
                    <Button onClick={() => approveProduct(e?._id)}>{selectedLanguage?.Approve}</Button>
                }</>
            }</>,
            showSorterTooltip: false,
            sorter: (a, b) => a?.isApproved - b?.isApproved,
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.Actions}</p>,
            key: 'actions',
            width: 300,
            render: (e) =>
                <div style={{ width: 330 }}>
                    <span >
                        {buUsers?.filter((v) => v?._id === selectedUser)[0]?.supplierType !== 'shops' ?
                            <>
                                < Button onClick={() => e?.reservation ?
                                    gotoReservationSetting(e)
                                    :
                                    (setReservationForm(true), setProductDetail(e))
                                }
                                >
                                    {selectedLanguage?.ReservationTool}
                                </Button>
                                &nbsp;&nbsp;
                            </>
                            : null}
                        <Button onClick={() => setEdit(e)}><EditOutlined />{selectedLanguage?.Edit}</Button>
                        &nbsp;&nbsp;
                        <Popconfirm
                            title={`${selectedLanguage?.Delete} ${convertTitle(`${e?.name} `)} ${selectedLanguage?.Product} ?`}
                            okText={selectedLanguage?.Delete}
                            cancelText={selectedLanguage?.Cancel}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => { deleteProduct(e?._id) }}
                        >
                            <Button href="#"><DeleteOutlined style={{ color: 'red' }} /> {selectedLanguage?.Delete}</Button>
                            {spin[e?._id] ? <span className='demo-loading-container'>
                                &nbsp;<Spin />
                            </span> : null}
                        </Popconfirm>
                    </span>
                </div >
        }
    ]
    return (<div className='new-exam-main-div'>
        <div className='exam-transaprent-div'>
            <p className='screen-qoute'> {selectedLanguage?.Quote2}</p>
        </div>
        <div className='new-exam-screen-upper'>
            {showForm || edit?._id ?
                <AddProducts
                    userDetail={buUsers?.filter((v) => v?._id === selectedUser)[0]}
                    getProducts={getProducts}
                    edit={edit}
                    selectedUser={selectedUser}
                    onCancel={() => (setShowForm(false), setEdit(null))}
                    {...props} />
                : showReservationForm ?
                    <AddReservation
                        allProduct={allProduct}
                        productDetail={productDetail}
                        selectedUser={selectedUser}
                        setReservationsetting={setReservationsetting}
                        setReservationData={setReservationData}
                        getProducts={getProducts} onCancel={() => setReservationForm(false)}
                        user={user}
                        {...props} />
                    :
                    reservationSetting ?
                        <ReservationSetting
                            resId={reservationData}
                            reservationData={reservationData}
                            setReservationsetting={setReservationsetting}
                            selectedUser={selectedUser}
                            getProducts={getProducts}
                            {...props}
                        />
                        :
                        <div style={{ background: 'white', padding: '15px' }}>
                            <h3 className='new-exam-title'>{selectedLanguage?.Product}</h3>
                            <Select
                                showArrow
                                allowClear={false}
                                defaultValue={selectedUser}
                                onChange={(e) => setSelectedUser(e)}
                                className='form-input'
                                style={{ width: 200, margin: '15px' }}
                                placeholder={selectedLanguage?.txt_SelectUser}
                                showSearch
                                optionFilterProp='children'
                                filterOption={checkSelectOption}
                            >
                                {buUsers?.map((v, i) => <Option value={v?._id} key={i}>{`${convertTitle(v?.fullName)} ${v?.email}`}</Option>)}
                            </Select>
                            <div style={{ textAlign: 'right', width: '99%' }}>
                                <Button
                                    type='primary'
                                    className='btn-end'
                                    onClick={() => setShowForm(true)}
                                >
                                    {selectedLanguage?.CreateProduct}
                                </Button>
                            </div>
                            <div className='new-layout-table-upper-div'>
                                <Table
                                    style={{ marginTop: '30px' }}
                                    className='new-layout-table'
                                    locale={customLocale}
                                    columns={columns}
                                    dataSource={allProduct}
                                    loading={loading}
                                    pagination={{ hideOnSinglePage: true }}
                                    scroll={{ x: 800 }}
                                />
                            </div>
                        </div>}
        </div>
    </div>
    )
}

export default AdminProducts

