const VISITER_HOME = `/`
const VISITER_FAQ = `/visiter-faq`
const HOME = `/home`
const GUEST = `/guest`
const LOGIN = `/login`
const BU_LOGIN = `/bu-login`
const SIGNUP = `/register`
const BU_SIGNUP = `/bu-register`
const PROFILE_SETTINGS = `/profile-settings`
const FORGOT = `/forgot`
const HELP = `/help`
const GROUP = `/group`
const TRANSFER = `/transfer`
const UPDATE_PASSWORD = `/update-password`
const BU_UPDATE_PASSWORD = `/bu-update-password`
const VERIFY_EMAIL = `/verifyemail`
const BU_USERS = `/bu-users`
const BU_FORGOT = `/bu-forgot`
const PRODUCT = `/product`
const BU_PRODUCT = `/bu-product`
const FAQ = `/faq`
const FINANCIAL = `/financial`
const BUFINANCIAL = `/bu-financial`
const BUVOUCHERS = `${BUFINANCIAL}/vouchers`
const BUINVOICES = `${BUFINANCIAL}/invoices`
const BUPAYMENTS = `${BUFINANCIAL}/Payments`
const VOUCHERS = `${FINANCIAL}/vouchers`
const INVOICES = `${FINANCIAL}/invoices`
const PAYMENTS = `${FINANCIAL}/Payments`
const ADMIN = `/admin`
const ADMIN_SUBADMINS = `${ADMIN}/sub-admins`
const ADMIN_USERS = `${ADMIN}/users`
const ADMIN_FINANCIAL = ADMIN + FINANCIAL
const ADMINVOUCHERS = `${ADMIN_FINANCIAL}/vouchers`
const ADMINBUINVOICES = `${ADMIN_FINANCIAL}/bu-invoices`
const ADMINGUESTINVOICES = `${ADMIN_FINANCIAL}/invoices`
const ADMINPAYMENTS = `${ADMIN_FINANCIAL}/Payments`
const ADMIN_LOGIN = `${ADMIN}/login`
const ADMIN_SETTINGS = `${ADMIN}/settings`
const ADMIN_TYPES = `${ADMIN_SETTINGS}/types`
const ADMIN_PRODUCT = `${ADMIN}/product`
const ADMIN_REVIEWS_FORM = `${ADMIN}/review-form`
const ADMIN_REVIEWS = `${ADMIN}/review`
const PRIV_PRODUCT = `/priv-product`
const REVIEW = `/review`
const GUEST_REVIEW = `/guest-review`
const BU_REVIEW = `/bu-review`
const REPORT = `${ADMIN}/report`
const PAYMENT_REPORT = `${REPORT}/payment`
const COUPONS_REPORT = `${REPORT}/coupons`
const SUBSCRIPTION_REPORT = `${REPORT}/subscription`
const FEE_REPORT = `${REPORT}/fee`
const COUPON = `/coupon`
const ADMIN_COUPON = `${ADMIN}${COUPON}`
const ADMIN_PRICE_SETTINGS = `${ADMIN}/price-settings`
const ADMIN_TAX_SETTINGS = `${ADMIN}/tax-settings`
const ADMIN_FAQS = `${ADMIN}/faq`
const ADMIN_ADD_FAQ = `${ADMIN}/add-faq`
const SUBSCRIPTION = `/subscription`
const SIDE_SETTING = `/side-setting`
const ADMIN_GROUP = `${ADMIN}${GROUP}`
const LOTTERY = `/lottery`
const GIFT_CARDS = `/gift-card`
const ADMIN_LOTTERY = `${ADMIN}${LOTTERY}`
const ADMIN_GIFT_CARDS = `${ADMIN}${GIFT_CARDS}`

export default {
    HOME,
    GUEST,
    LOGIN,
    SIGNUP,
    BU_LOGIN,
    SIGNUP,
    BU_SIGNUP,
    HELP,
    TRANSFER,
    FORGOT,
    UPDATE_PASSWORD,
    VERIFY_EMAIL,
    PROFILE_SETTINGS,
    BU_USERS,
    BU_FORGOT,
    BU_UPDATE_PASSWORD,
    PRODUCT,
    BU_PRODUCT,
    FAQ,
    ADMIN,
    ADMIN_LOGIN,
    ADMIN_SETTINGS,
    ADMIN_TYPES,
    VOUCHERS,
    INVOICES,
    PAYMENTS,
    FINANCIAL,
    ADMIN_PRODUCT,
    PRIV_PRODUCT,
    BUVOUCHERS,
    BUINVOICES,
    BUPAYMENTS,
    BUFINANCIAL,
    ADMIN_FINANCIAL,
    ADMINVOUCHERS,
    ADMINBUINVOICES,
    ADMINGUESTINVOICES,
    ADMINPAYMENTS,
    ADMIN_USERS,
    ADMIN_REVIEWS_FORM,
    ADMIN_REVIEWS,
    REVIEW,
    GUEST_REVIEW,
    REPORT,
    PAYMENT_REPORT,
    COUPON,
    ADMIN_COUPON,
    ADMIN_PRICE_SETTINGS,
    BU_REVIEW,
    SUBSCRIPTION,
    SIDE_SETTING,
    ADMIN_SUBADMINS,
    ADMIN_TAX_SETTINGS,
    COUPONS_REPORT,
    SUBSCRIPTION_REPORT,
    FEE_REPORT,
    ADMIN_GROUP,
    GROUP,
    LOTTERY,
    ADMIN_LOTTERY,
    ADMIN_GIFT_CARDS,
    GIFT_CARDS,
    ADMIN_ADD_FAQ,
    ADMIN_FAQS,
    VISITER_HOME,
    VISITER_FAQ
}