import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, DatePicker, Popconfirm, Spin, Table } from 'antd'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { GET, POST } from '../../utils/apis'
import { assignMessage, convertTitle, errorMessage, successMessage } from '../../utils/helpers'
import LotteryCodes from './LotterCodes'
import LotteryWinners from './LotteryWinners'
import UpdateLottery from './UpdateLottery'
import WithdrawRequest from './WithdrawRequest'
import { IoIosArrowBack } from "react-icons/io"
import NoData from "../../assets/noDataImae.png"

const BusinessLottery = (props) => {
    const { user, selectedLanguage, language } = props
    const [showCreateLottery, setShowCreateLottery] = useState(false)
    const [showWithDrawForm, setShowWithDrawForm] = useState(false)
    const [showLotteryWinners, setShowLotteryWinners] = useState(false)
    const [showLotteryCodes, setShowLotteryCodes] = useState(false)
    const [edit, setEdit] = useState({})
    const [loading, setLoading] = useState(false)
    const [lottery, setlottery] = useState([])
    const [filteredArray, setfilteredArray] = useState([])
    const [dateRange, setDateRange] = useState({
        date1: moment().format('YYYY-MM')
    })

    const makArray = lottery?.map((v, i) => {
        return {
            text: v?.date,
            value: v?.date,
        }
    })

    const customLocale = {
        emptyText: selectedLanguage?.txt_Data_No_tFound,
        sortTitle: selectedLanguage?.txt_clicktosort,
        sortAscending: selectedLanguage?.txt_sortAscending,
        sortDescending: selectedLanguage?.txt_sortDescending,
    };

    useEffect(() => {
        setfilteredArray(_.uniqBy(makArray, 'text'))
    }, [lottery])

    useEffect(() => {
        getLottery()
    }, [dateRange])

    const getLottery = () => {
        setLoading(true)
        let valObj = { userId: `${user?._id}`, date1: dateRange?.date1 }
        axios.post(`${GET.GET_USER_LOTTERIES}`, valObj)
            .then((res) => {
                const { data } = res
                setlottery(data?.data)
                // console.log('dataaaaget', data)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
            })
    }

    const deleteLottery = (e) => {
        let getDays = getDaysDiff(moment(e.date).utcOffset(0, true).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')) >= 1 ? false : true

        if (!getDays) {
            errorMessage(selectedLanguage?.errorMessage_CannotDeleteLottery)
        } else {
            setLoading(true)
            let valObj = {
                _id: e?._id, userId: `${user?._id}`
            }
            axios.post(`${POST.DELETE_LOTTERY_PRIZE} `, valObj)
                .then((res) => {
                    const { data } = res
                    setLoading(false)
                    if (data.success) {
                        successMessage(assignMessage(data?.message, language?.language))
                        getLottery()
                    } else {
                        return errorMessage(assignMessage(data?.message, language?.language))
                    }
                })
                .catch((e) => {
                    setLoading(false)
                })
        }
    }

    const getDaysDiff = (start_date, end_date, date_format = 'YYYY-MM-DD') => {
        const getDateAsArray = (date) => {
            return moment(date.split(/\D+/), date_format);
        }
        return getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days') + 1;
    }


    const columns = [
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_LotteyPrize}</p>,
            dataIndex: 'prize',
            key: 'prize',
            // sorter: (a, b) => a.prize - b.prize,
            render: (e) => <img src={`${e}`} style={{ marginRight: 10, width: 86, height: 60 }} />

        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_PrizeName}</p>,
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_LotteyMonth}</p>,
            dataIndex: 'date',
            key: 'date',
            // filters: filteredArray,
            // onFilter: (value, record) => record.date.indexOf(value) === 0,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{moment(e).utcOffset(0, true).format('YYYY/MM')}</p>
        },
        {
            title: <p className='table-title-p'>{selectedLanguage?.title_PrivateLottery}</p>,
            dataIndex: 'isPublic',
            key: 'isPublic',
            sorter: (a, b) => a.isPublic - b.isPublic,
            showSorterTooltip: false,
            render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{e ? selectedLanguage?.True : selectedLanguage?.False}</p>
            // filters: filteredArray,
            // onFilter: (value, record) => record.date.indexOf(value) === 0,
            // render: (e) => <p style={{ paddingTop: 15, marginTop: -5 }}>{moment(e).utcOffset(0, true).format('DD/MM/YYYY')}</p>
        },
        {
            // title: 'Action',
            key: 'action',
            render: (e) => (
                <>
                    <Popconfirm
                        title={selectedLanguage?.title_DeletethisLottery}
                        okText={selectedLanguage?.Delete}
                        cancelText={selectedLanguage?.Cancel}
                        okButtonProps={{
                            type: 'primary',
                            className: 'form-button'
                        }}
                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                        onConfirm={() => { deleteLottery(e) }}
                    >
                        <a ><DeleteOutlined style={{ color: 'red' }} /> {selectedLanguage?.Delete}</a>
                    </Popconfirm>

                    <a onClick={() => { showEdit(e) }}><EditOutlined style={{ marginLeft: 10 }} /> {selectedLanguage?.Edit}</a>
                </>
            )
        }
    ]

    const showEdit = (e) => {
        let getDays = getDaysDiff(moment(e.date).utcOffset(0, true).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')) >= 1 ? false : true

        if (!getDays) {
            errorMessage(selectedLanguage?.errorMessage_CannotEditLottery)
        } else {
            setEdit(e)
            setShowCreateLottery(true)
            getLottery()
        }
    }
    const createReportRange = (d) => {
        setDateRange({
            date1: d?.format('YYYY-MM')
        })
    }
    let currentDate = moment().format('YYYY-MM')
    return (
        <div className='main-div-new-style'>
            {
                showCreateLottery || showLotteryWinners || showLotteryCodes || showWithDrawForm ?
                    <div style={{ textAlign: 'right', width: '99.5%', marginTop: '10px' }}>
                        <Button
                            type='primary'
                            style={{ height: '40px', width: '130px', marginTop: '2px' }} onClick={() => {
                                setShowCreateLottery(false);
                                setShowLotteryWinners(false);
                                setShowLotteryCodes(false);
                                setShowWithDrawForm(false);
                                setEdit({})
                            }} size={32} >{selectedLanguage?.Back}</Button>
                    </div>
                    : null}


            <p className='new-exam-title'>{
                showCreateLottery && !edit?._id ?
                    selectedLanguage?.Create + selectedLanguage?.title_LotteyPrizes :
                    showCreateLottery && edit?._id ?
                        selectedLanguage?.Update + selectedLanguage?.title_LotteyPrizes :
                        showLotteryWinners ?
                            selectedLanguage?.title_LotteryWinners :
                            showLotteryCodes ?
                                selectedLanguage?.button_LotteryCodes :
                                showWithDrawForm ?
                                    selectedLanguage?.button_WithDrawRequest :
                                    selectedLanguage?.title_lottery}</p>


            {showCreateLottery ?
                <UpdateLottery getLottery={() => getLottery()} edit={edit} user={user} onCancel={() => (setShowCreateLottery(false), setEdit(null))} {...props} />
                : showLotteryWinners ? <LotteryWinners user={user} onCancel={() => (setShowLotteryWinners(false), setEdit(null))} {...props} />
                    : showLotteryCodes ? <LotteryCodes user={user} onCancel={() => (setShowLotteryCodes(false), setEdit(null))} {...props} />
                        : showWithDrawForm ? <WithdrawRequest user={user} onCancel={() => (setShowWithDrawForm(false), setEdit(null))} {...props} />
                            :
                            <div>
                                <div className='flex-mobile' style={{ display: 'flex' }}>
                                    <DatePicker
                                        defaultValue={moment(currentDate, 'YYYY-MM')}
                                        style={{
                                            marginBottom: 10,
                                            marginTop: 20,
                                            width: '250px',
                                            height: '45px',
                                            borderRadius: '6px',
                                            boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                                            marginLeft: '8px'
                                        }}
                                        picker={'month'}
                                        onChange={createReportRange}
                                        allowClear={false}
                                    />
                                </div>
                                <div
                                    className='button-flex-box' style={{ marginRight: '5px' }}>
                                    <Button
                                        style={{ height: '40px', width: '140px', margin: '2px', marginTop: '10px' }}
                                        className='addproduct-text'
                                        type='primary'
                                        onClick={() => setShowLotteryWinners(true)}
                                    >
                                        {selectedLanguage?.title_LotteryWinners}
                                    </Button>
                                    <Button
                                        style={{ height: '40px', width: '140px', margin: '2px', marginTop: '10px' }}
                                        className='addproduct-text'
                                        type='primary'
                                        onClick={() => setShowLotteryCodes(true)}
                                    >
                                        {selectedLanguage?.button_LotteryCodes}
                                    </Button>
                                    <Button
                                        style={{ height: '40px', width: '140px', margin: '2px', marginTop: '10px' }}
                                        className='addproduct-text'
                                        type='primary'
                                        onClick={() => setShowCreateLottery(true)}
                                    >
                                        {selectedLanguage?.button_CreateLottery}
                                    </Button>
                                    <Button
                                        style={{ height: '40px', width: '140px', margin: '2px', marginTop: '10px' }}
                                        className='addproduct-text'
                                        type='primary'
                                        onClick={() => setShowWithDrawForm(true)}
                                    >
                                        {selectedLanguage?.button_WithDrawRequest}
                                    </Button>
                                </div>
                                <div className='new-layout-table-upper-div'>
                                    <Table
                                        className='new-layout-table'
                                        locale={customLocale}
                                        loading={loading}
                                        columns={columns}
                                        dataSource={lottery}
                                        pagination={{ hideOnSinglePage: true }}
                                        scroll={{ x: 800 }}
                                    />
                                </div>
                            </div>}
        </div>
    )
}

export default BusinessLottery