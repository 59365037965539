import { AlertOutlined } from '@ant-design/icons';
import { Menu, Popconfirm, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { removeUser } from '../../Redux/actions/authActions';
import { setLanguage } from '../../Redux/actions/languageAction';
import { allPaths, drawerGuestRoutes, drawerRoutes } from '../../utils/constants';
import { setKeyMenu } from '../../utils/helpers';
import MenuModal from './MenuModal';

const NewHeader = (props) => {
    const { user, language, selectedLanguage } = props
    const { SubMenu } = Menu
    const [selected, setSelected] = useState('GB');
    const [logoutLoad, setLogoutLoad] = useState(false);
    let teacherRoutes = drawerRoutes
    const isEnglish = language?.language === 'english'
    const isEgypt = language?.language === 'egypt'
    const isGuest = user?.userType === 'guest'

    const dispatch = useDispatch()
    const history = useHistory()
    const currentPage = window?.location?.pathname

    useEffect(() => {
        language?.language === 'english' ? setSelected('GB') :
            language?.language === 'egypt' ? setSelected('EG') :
                setSelected('NL')
    })

    const onchangeLanguage = (v) => {
        setSelected(v)
        if (v === 'GB') {
            dispatch(setLanguage('english'))
        }
        else if (v === 'EG') {
            dispatch(setLanguage('egypt'))
        }
        else {
            dispatch(setLanguage('netherlands'))
        }
    }
    const logout = () => {
        setLogoutLoad(true)
        setTimeout(() => {
            setLogoutLoad(false)
            dispatch(removeUser())
            history.push(allPaths.VISITER_HOME)
        }, 1500);
    }

    return (
        <div className='new-main-header-div' >

            <div className='new-main-header-inner-top'>
                <div className='new-upper-header'>
                    <div className='upper-header-left'>
                        <p className='upper-header-txt-logo'>
                            CITY-DEALS
                            <span>
                                {selectedLanguage?.txt_EXPERIENCEYOULLNEVERFORGET}
                            </span>
                        </p>
                    </div>
                    <div className='upper-header-right'>
                        <p className='uppr-icon-main'>
                            <span className='upper-header-icon'><FaFacebookF size={18} /></span>
                            <span className='upper-header-icon'><FaTwitter size={18} /></span>
                            <span className='upper-header-icon'><FaInstagram size={18} /></span>
                        </p>
                        <span className='round-lang-upper-header-icon'>
                            <ReactFlagsSelect
                                className='round-countries-select-update'
                                fullWidth={false}
                                showOptionLabel={false}
                                showSelectedLabel={false}
                                countries={['NL', 'GB', 'EG'/* , 'ES', 'FR', 'DE' */]}
                                selected={selected}
                                onSelect={(v) => onchangeLanguage(v)}
                            />
                        </span>
                    </div>
                </div>
            </div>

            <div className='new-main-header-inner-bottom'>
                <div className='new-lower-nav'>
                    <div className='lower-nav-left'>
                        <span className='new-menu-for-web' style={{ display: 'flex' }}>
                            <Menu
                                defaultOpenKeys={[`${setKeyMenu(window?.location?.pathname)}`]}
                                defaultSelectedKeys={[`${window?.location?.pathname}`]}
                                mode='horizontal'
                                className='me-new-top-menu'
                            >
                                <>
                                    {(isGuest ? drawerGuestRoutes : teacherRoutes)?.map((v, i) => {
                                        if (v?.isSubMenu && v?.children?.length) {
                                            return (
                                                <SubMenu
                                                    key={v?.route}
                                                    title={
                                                        <span className='my-menu-txtt'>
                                                            {isEnglish ? v.title : isEgypt ? v?.titleEgypt : v.titleNl}
                                                            <span style={{ paddingLeft: '5px', paddingTop: '3px' }}>{<IoIosArrowDown color={'white'} />} </span>
                                                        </span>}

                                                    className='new-header-sub-menu'
                                                    style={{ color: 'white' }}>
                                                    {v?.children?.map((y, j) => {
                                                        return (
                                                            <Menu.Item key={y?.route}  >
                                                                <Link
                                                                    to={y?.route}
                                                                >
                                                                    <span className={y?.route === currentPage ?
                                                                        'new-menu-c-item-active' : 'new-menu-c-item'}>{isEnglish ? y?.title : isEgypt ? y?.titleEgypt : y?.titleNl}</span>
                                                                </Link>
                                                            </Menu.Item>
                                                        )
                                                    })}
                                                </SubMenu>
                                            )
                                        }
                                        return (
                                            <Menu.Item key={v.route}
                                                className='new-header-sub-menu'
                                            >
                                                <Link
                                                    to={v?.route}
                                                >
                                                    <span className='my-menu-txtt'
                                                        style={{ color: 'white' }}
                                                    >{isEnglish ? v.title : isEgypt ? v?.titleEgypt : v.titleNl}</span>
                                                </Link>
                                            </Menu.Item>
                                        )
                                    })}
                                </>
                            </Menu>
                        </span>
                        <span className='new-menu-for-mobile'>
                            <MenuModal {...props} />
                        </span>

                    </div>
                    <div className='lower-nav-right'>
                        <p className='button-triangle'></p>
                        <Popconfirm
                            title={selectedLanguage?.txt_Areyousuretologout}
                            okText={selectedLanguage?.txt_Logout}
                            cancelText={selectedLanguage?.Cancel}
                            okButtonProps={{
                                type: 'primary',
                                className: 'form-button'
                            }}
                            icon={<AlertOutlined style={{ color: '#ec671b', fontSize: '25px' }} />}
                            cancelButtonProps={{ style: { borderRadius: 4 } }}
                            onConfirm={() => logout()}
                        >
                            <p className='lower-nav-right-bton'  >{logoutLoad ? <Spin /> : selectedLanguage?.txt_Logout}</p>
                        </Popconfirm>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default NewHeader