import axios from 'axios'
import momentTz from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { loginUser, removeUser } from '../../Redux/actions/authActions'
import { setCities, setCollapsed, setTaxes } from '../../Redux/actions/generalActions'
import { SetMenuNone } from '../../Redux/actions/menuNoneAction'
import { addAllBuInvoices, addAllBuProducts, addAllGiftCardSubscriptionDetail, addAllInvoices, addAllLotterySubscriptionDetail, addAllPayments, addAllSubscriptions, addAllVouchers, addUserFaq, addUserJoinedGroups, addUserPendingGroups, setRates } from '../../Redux/actions/userActions'
import { GET } from '../../utils/apis'
import { allPaths } from '../../utils/constants'
import { allBUserData, allUserData, assignLanguage, generalData } from '../../utils/helpers'
import NewFooter from '../Footer/NewFooter'
import NewHeader from '../Header/NewHeader'

const MenuLayoutUpdate = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.authReducer.user)
    const language = useSelector(state => state?.languageReducer)
    const inlineCollapsed = useSelector(state => state.generalReducer.inlineCollapsed)
    const MenuNone = useSelector(state => state.menuNoneReducer.menuNone)
    const [updateWallet, setUpdateWallet] = useState(false)
    const filter = useSelector(state => state.userReducer.filter)
    const rates = useSelector(state => state.userReducer.rates)
    const selectedLanguage = assignLanguage(language?.language)
    const isEnglish = language?.language === 'english'
console.log('user',user)
    useEffect(() => {
        setUpdateWallet(true)
        window.addEventListener('resize', setWindowWidth)
        setWindowWidth()
        dispatch(SetMenuNone(false))
        if (user?.userType) {
            allBUserData.all(userActions, user?._id)
        } else {
            allUserData.all(userActions, user?._id)
        }

        generalData.all(generalActions, true)
    }, [])

    const setWindowWidth = () => {
        // window.innerWidth < 700 ? dispatch(setCollapsed(true)) : dispatch(setCollapsed(false))
    }

    const authActions = {
        loginUser: (e) => dispatch(loginUser(e)),
        removeUser: () => dispatch(removeUser())
    }

    const userActions = {
        addAllInvoices: (e) => dispatch(addAllInvoices(e)),
        addAllVouchers: (e) => dispatch(addAllVouchers(e)),
        addAllPayments: (e) => dispatch(addAllPayments(e)),
        addAllBuInvoices: (e) => dispatch(addAllBuInvoices(e)),
        addAllSubscriptions: (e) => dispatch(addAllSubscriptions(e)),
        setRates: (e) => dispatch(setRates(e)),
        addUserJoinedGroups: (e) => dispatch(addUserJoinedGroups(e)),
        addUserPendingGroups: (e) => dispatch(addUserPendingGroups(e)),
        addAllLotterySubscriptionDetail: (e) => dispatch(addAllLotterySubscriptionDetail(e)),
        addAllGiftCardSubscriptionDetail: (e) => dispatch(addAllGiftCardSubscriptionDetail(e)),
        addUserFaq: (e) => dispatch(addUserFaq(e)),
        addAllBuProducts: (e) => dispatch(addAllBuProducts(e)),

    }

    const generalActions = {
        setCollapsed: (e) => dispatch(setCollapsed(e)),
        setCities: (e) => dispatch(setCities(e)),
        setTaxes: (e) => dispatch(setTaxes(e)),
    }

    if (!user) {
        return (
            <Redirect to={allPaths?.LOGIN} />
        )
    }

    return (
        <Route
            {...rest}
            render={props => <AddMenu
                {...props}
                selectedLanguage={selectedLanguage}
                isEnglish={isEnglish}
                rates={rates}
                language={language}
                filter={filter}
                component={Component}
                user={user}
                authActions={authActions}
                MenuNone={MenuNone}
                inlineCollapsed={inlineCollapsed}
                dispatch={dispatch}
                generalActions={generalActions}
                userActions={userActions}
                updateWallet={updateWallet}
                setUpdateWallet={setUpdateWallet} />}
        />
    )
}

const AddMenu = ({ component: Component, ...props }) => {
    const { user, dispatch, history, selectedLanguage } = props

    useEffect(() => {
        if (user) {
            getUser()
            let timeZone = momentTz.tz.zonesForCountry(user?.country || 'NL')
            momentTz.tz.setDefault(timeZone[0] || 'Europe/Amsterdam')
        }

        try {
        }
        catch (e) {
        }
    }, [props?.location?.pathname])

    const getUser = () => {
        axios.get(`${GET?.USER_BY_ID}/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    dispatch(loginUser(data.user))
                }
                else {
                    history?.replace(allPaths?.LOGIN)
                    dispatch(removeUser())
                }
            })
            .catch((e) => {
                dispatch(removeUser())
            })
    }

    if (!user) {
        return (
            <Redirect to={allPaths?.LOGIN} />
        )
    }

    return (
        <div className='new-main-layout-div '>
            <div style={{ height: '100vh', overflow: 'auto' }} className='new-layout-scroll-class'>
                <NewHeader user={user} selectedLanguage={selectedLanguage} {...props} />
                <div className='new-main-component-screen-div'>
                    <Component user={user} history={history} getUser={getUser} {...props} />
                </div>
                <NewFooter history={history} selectedLanguage={selectedLanguage} user={user} />
            </div>
        </div>
    )
}

export default MenuLayoutUpdate