import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Typography, Menu, Dropdown, Carousel } from 'antd'
import axios from 'axios'
import { QrReader } from 'react-qr-reader'
import React, { useEffect, useState } from 'react'
import { POST } from '../../utils/apis'
import { constraints } from '../../utils/constants'
import { allUserData, assignMessage, errorMessage, successMessage } from '../../utils/helpers'

const { Title } = Typography

const AddLottery = (props) => {
    const { user, userActions, getAllSavings, selectedLanguage, language } = props
    const [visible, setVisible] = useState(false)
    const [visibleQR, setVisibleQR] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState([])
    const [lottery, setLottery] = useState('')
    const [QRCode, setQRCode] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const isEnglish = language?.language === 'english' ? true : false

    useEffect(() => {
        allUserData?.all(userActions, user?._id)
    }, [])
    useEffect(() => {
        if (QRCode && !loading) {
            console.log('QRCode', QRCode)
            handleOk(QRCode)
        }
    }, [QRCode])

    const menu = (
        <Menu>
            <Menu.Item onClick={() => setVisible(true)} key='1'>{selectedLanguage?.txt_ClaimByCode}</Menu.Item>
            <Menu.Item onClick={() => setVisibleQR(true)} key='2'>{selectedLanguage?.txt_ClaimByQRCode}</Menu.Item>
        </Menu>
    )

    const handleOk = (code = null) => {
        setConfirmLoading(true)
        setLoading(true)

        axios.post(POST.CLAIM_LOTTERY, { lotteryCode: lottery || code, userId: user?._id })
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    handleCancel()
                    successMessage(assignMessage(data?.message, language?.language))
                    setVisible(false)
                    setQRCode(null)
                    setVisibleQR(false)
                    setConfirmLoading(false)
                    allUserData?.all(userActions, user?._id)
                    getAllSavings()
                    if (data?.advertisement || data?.groupAdminAdvertisement) {
                        // console.log('data ADV', [data?.advertisement, data?.groupAdminAdvertisement])
                        setImage([data?.advertisement, data?.groupAdminAdvertisement] || [])
                    }

                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            })
            .catch((e) => {
                console.log('e ', e)
                setVisible(false)
                setConfirmLoading(false)
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })

        showModal1()
    }

    const handleCancel = () => {
        setVisible(false)
        setVisibleQR(false)
        showModal1()
        setLottery('')
    }

    const showModal1 = () => {
        setIsModalVisible(true)
    }

    const handlelotteryInput = (e) => {

        let value = e?.target?.value?.replace(/ /g, '')
        let event = e?.nativeEvent?.data

        if (event && ((value?.length === 3 || value?.length === 7) || (lottery?.length === 3 || lottery.length === 7))) {
            if (lottery?.length === 3 || lottery.length === 7) {
                value = `${lottery}-${event}`
            }
            else {
                value = `${value}-`
            }
        }

        setLottery(value)
    }

    const handleCancel1 = () => {
        setImage({})
    }

    const scanQr = async qr => {
        let code = qr?.text

        if (code) {
            setQRCode(code)
        }
    }

    return (
        <div>
            <div style={{ textAlign: 'right', width: '99%' }}>
                <Dropdown overlay={menu} placement='bottomLeft'>
                    <Button
                        style={{ height: '40px', width: '160px', marginTop: '10px' }}
                        className='addproduct-text'
                        type='primary'>
                        {selectedLanguage?.txt_ClaimLottery}
                    </Button>
                </Dropdown>
            </div>

            <Modal
                className='pop-info-modal'
                style={{ top: '10px' }}
                open={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={null}
            // footer={[
            //     <Button key='back'
            //         onClick={handleCancel}
            //         style={{ height: '40px', width: '100px', marginTop: '10px' }}
            //         className='cancel-btn'>
            //         Cancel
            //     </Button>,
            //     <Button
            //         key='ok'
            //         onClick={handleOk}
            //         disabled={lottery?.length !== 11}
            //         style={{ height: '40px', width: '160px', marginTop: '10px' }}
            //         className='addproduct-text'
            //         type='primary'
            //         loading={loading}
            //     >
            //         Claim Lottery
            //     </Button>
            // ]}
            >
                <p className='new-p-question'>{selectedLanguage?.txt_Lottery}</p>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4 style={{ color: '#ec671b' }}>{selectedLanguage?.txt_EnteryourLotterynumber}</h4>
                    <div className='form-input-single-input'>
                        <Input
                            // style={{ paddingTop: '15px' }}
                            className='form-input-new-style'
                            autoFocus
                            required
                            maxLength={11}
                            value={lottery}
                            onChange={handlelotteryInput}
                            placeholder='e.g: 456-786-ab6'
                        />
                    </div>
                    <Button
                        key='ok'
                        onClick={handleOk}
                        disabled={lottery?.length !== 11}
                        style={{ height: '40px', width: '100%', marginTop: '10px' }}
                        className='addproduct-text'
                        type='primary'
                        loading={loading}
                    >
                        {selectedLanguage?.txt_ClaimLottery}
                    </Button>
                </div>
            </Modal>

            <Modal
                // title={<center>
                //     <Title level={4} style={{ color: '#ec671b' }}>Activate QR</Title>
                // </center>}
                className='pop-info-modal'
                style={{ top: '10px' }}
                open={visibleQR}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={null/* [
                    <Button key='back' onClick={handleCancel}
                        style={{ height: '40px', width: '100px', marginTop: '10px' }}
                        className='cancel-btn'>
                        Cancel
                    </Button>
                ] */}
            >
                <p className='new-p-question'>{selectedLanguage?.txt_ActivateQR}</p>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4 style={{ color: '#ec671b' }}>{selectedLanguage?.txt_ScanyourQR}</h4>
                    <QrReader
                        delay={1000}
                        onError={e => errorMessage(selectedLanguage?.txt_WebCamNotFound)}
                        constraints={constraints}
                        onResult={scanQr}
                        style={{ width: '100%' }}
                    />
                </div>
                <Button key='back' onClick={handleCancel}
                    style={{ height: '40px', width: '100%', marginTop: '10px' }}
                    className='cancel-btn'>
                    {selectedLanguage?.txt_Cancel}
                </Button>
            </Modal>

            <Modal
                bodyStyle={{ padding: '0' }}
                footer={null}
                open={image[0]?.imageUrl}
                // visible={true}
                onCancel={handleCancel1}
                height={10}
                // width={'fit-content'}
                closable={false}
                className='pop-info-modal'
                style={{ top: '10px' }}
            >
                <button class='close' onClick={handleCancel1}>
                    <CloseCircleOutlined />
                </button>
                <Carousel
                    swipe
                    // speed={2}
                    autoplaySpeed={1000}
                    autoplay
                    arrows
                >
                    {image.length && image.map((v, i) => (
                        <div>
                            <img style={{ width: '100%', height: '50vh' }} src={v?.imageUrl} />
                        </div>
                    ))}
                </Carousel>
                {/* <div>
                    <img style={{ width: '100%', height: '50vh' }} src={image?.imageUrl} alt='advertisement' />
                </div> */}
            </Modal>
        </div >
    )
}

export default AddLottery