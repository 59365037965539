import { Button, Card, Input, Popconfirm, Spin } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { CiEdit } from 'react-icons/ci'
import { TbAdjustmentsHorizontal } from 'react-icons/tb'
import { useSelector } from 'react-redux'
import NoCards from '../../assets/NoCards.png'
import { POST } from '../../utils/apis'
import { allBUserData, convertTitle, stringLimiter, successNotification } from '../../utils/helpers'
import AddProducts from './AddProduct'
import AddReservation from './AddReservation'
import ChangeCode from './ChangeCode'
import ReservationSetting from './ReservationSetting'

const { Search } = Input

const Products = (props) => {
    const { user, language, selectedLanguage, userActions } = props
    const buProducts = useSelector(state => state.userReducer.buProducts) || []
    const [loading, setLoading] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [showReservationForm, setReservationForm] = useState(false)
    const [reservationSetting, setReservationsetting] = useState(false)
    const [showPrivate, setShowPrivate] = useState(false)
    const [reservationData, setReservationData] = useState(null)
    const [searchTxt, setSearchTxt] = useState(null)
    const [edit, setEdit] = useState(false)
    const [filteredArray, setFilteredArray] = useState(buProducts || [])
    const [spin, setSpin] = useState({})
    const [productDetail, setProductDetail] = useState({})

    useEffect(() => {
        allBUserData?.getAllBuProducts(userActions, user?._id)
    }, [])
    useEffect(() => {
        searchProduct(searchTxt)
    }, [searchTxt, buProducts])
    const gotoReservationSetting = (data) => {
        setReservationData(data?.reservation)
        setReservationsetting(true)
    }

    const deleteProduct = (_id) => {
        setSpin({ [_id]: true })
        let deleteProduct = {
            _id: _id,
            userId: user?._id
        }
        axios.post(`${POST?.DELETE_PRODUCT}`, deleteProduct)
            .then((res) => {
                const { data } = res
                setSpin({})
                allBUserData?.getAllBuProducts(userActions, user?._id)
                if (data?.success) {
                    successNotification(selectedLanguage?.SuccessfullyDeleted)
                }
            })
            .catch((e) => {
                setSpin({})
            })
    }
    const searchProduct = (val) => {
        let tempArr = [...buProducts]
        if (!val) {
            setFilteredArray(tempArr)
        } else {
            setFilteredArray(tempArr?.filter((v) => v?.name?.toLowerCase()?.includes(val?.toLowerCase())))
        }
    }

    return (
        <>
            <div className='home-div-bg-img'>
                <div className='home-txt-div'>
                    <p className='new-home-first-p'>{selectedLanguage?.txt_WELCOMETOCITYDEALS} </p>
                    <p className='new-home-snd-p'>{selectedLanguage?.txt_EXPERIENCEYOULLNEVERFORGET} </p>
                    <p className='new-home-trd-p'>{selectedLanguage?.txt_Farfarawaybehindtheword}</p>
                    <br />
                    <div className='div-flex-back-name'>
                        <div>
                            <Search
                                className='new-input-search'
                                placeholder={selectedLanguage?.New_SearchProductName || 'SearchProductName'}
                                // onSearch={searchProduct} enterButton />
                                onSearch={setSearchTxt} enterButton />
                        </div>
                        <div className='new-filter-btn' >
                            <p className='new-filter-btn-text'>
                                {selectedLanguage?.Filter}</p>
                            <TbAdjustmentsHorizontal size={20}
                                color={'rgba(0, 0, 0, 0.4)'}
                                style={{ marginRight: '5px' }} />
                        </div>
                    </div>
                    <br />
                </div>
            </div>
            <div style={{ background: 'white', padding: '10px 0px 10px 0px' }}>
                {showPrivate ?
                    <ChangeCode
                        onCancel={() => setShowPrivate(false)}
                        {...props} />
                    :
                    showForm || edit?._id ?
                        <AddProducts
                            edit={edit}
                            onCancel={() => (setShowForm(false), setEdit(null))}
                            {...props} />
                        : showReservationForm ?
                            <AddReservation
                                allProduct={buProducts}
                                productDetail={productDetail}
                                setReservationsetting={setReservationsetting}
                                setReservationData={setReservationData}
                                onCancel={() => setReservationForm(false)}
                                {...props} />
                            :
                            reservationSetting ?
                                <ReservationSetting
                                    resId={reservationData}
                                    reservationData={reservationData}
                                    setReservationsetting={setReservationsetting}
                                    user={user}
                                    {...props}
                                />
                                :
                                <div className='main-div-new-style'>
                                    <p className='new-exam-title'>{selectedLanguage?.Products}</p>

                                    <div style={{ textAlign: 'right', width: '99.5%', marginTop: '10px' }}>
                                        <Button
                                            type='primary'
                                            style={{ height: '40px', width: '130px', marginTop: '2px' }}

                                            onClick={() => setShowPrivate(true)}
                                        >{selectedLanguage?.PrivateCode}</Button>
                                        &nbsp;
                                        <Button
                                            type='primary'
                                            style={{ height: '40px', width: '150px', marginTop: '2px', marginLeft: '2px', }}

                                            onClick={() => setShowForm(true)}
                                        >{selectedLanguage?.CreateProduct}</Button>
                                    </div>

                                    <div className='MobileTable'>
                                        <div style={{ width: '98%', margin: '10px auto' }}>
                                            {
                                                loading ?
                                                    [1, 2, 3, 4].map((v, i) => {
                                                        return (<Card
                                                            key={i}
                                                            loading={loading}
                                                            bordered={false}
                                                            className='loading-card-style'
                                                            style={{
                                                                width: '100%',
                                                                minWidth: '100%',
                                                                height: '200px'
                                                            }}
                                                        />)
                                                    }) :
                                                    filteredArray?.length
                                                        ? filteredArray?.map((v, i) => {
                                                            return (
                                                                <div
                                                                    key={i}
                                                                    style={{
                                                                        padding: '10px',
                                                                        width: '100%',
                                                                        minWidth: '100%',
                                                                        borderRadius: '4px',
                                                                        margin: '4px',
                                                                        marginTop: '10px',
                                                                        boxShadow: ' 0 4px 5px 0 rgba(0, 0, 0, 0.3)',
                                                                        cursor: 'pointer'
                                                                    }}>
                                                                    <>
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                            <div style={{ marginRight: '5px' }}>
                                                                                <img src={v?.imageUrl} style={{
                                                                                    height: '120px',
                                                                                    width: '120px',
                                                                                    maxWidth: '120px',
                                                                                    borderRadius: '4px'
                                                                                }} />
                                                                            </div>
                                                                            <div style={{ width: '100%', }}>
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'row',
                                                                                    justifyContent: 'space-between',
                                                                                    width: '100%',
                                                                                }}>
                                                                                    <p style={{
                                                                                        fontSize: 14,
                                                                                        fontWeight: 'bold',
                                                                                        color: 'black',
                                                                                        textAlign: 'start',
                                                                                        whiteSpace: 'nowrap',
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                        width: '80%'
                                                                                    }}>{stringLimiter(v?.name, 14) || '------'}</p>
                                                                                    <p style={{ color: '#ec671b' }}>€{v?.productType?.feeCost ? v?.productType?.feeCost : 0}</p>
                                                                                </div>
                                                                                <div>
                                                                                    <p style={{
                                                                                        color: 'grey',
                                                                                        textAlign: 'start',
                                                                                        lineHeight: 1.4,
                                                                                        height: '42px',
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                        lineBreak: 'anywhere',
                                                                                        marginBottom: 0
                                                                                    }}>
                                                                                        {v?.description || '-------------------------------'}
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p style={{
                                                                                        color: 'grey',
                                                                                        textAlign: 'start',
                                                                                        lineHeight: 1.4,
                                                                                        marginBottom: 0
                                                                                    }}>
                                                                                        {selectedLanguage?.Topic}: {v?.topicId?.topicName}
                                                                                    </p>
                                                                                    <p style={{
                                                                                        textAlign: 'start',
                                                                                        lineHeight: 1.4,
                                                                                        color: '#ec671b',
                                                                                        marginBottom: 0
                                                                                    }}>
                                                                                        {selectedLanguage?.Sold}: {v?.sold}
                                                                                    </p>
                                                                                    <p style={{
                                                                                        color: 'grey',
                                                                                        textAlign: 'start',
                                                                                        lineHeight: 1.4,
                                                                                        marginBottom: 0
                                                                                    }}>
                                                                                        {selectedLanguage?.PrivateCode}:{v?.productCode || '------'}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                            <div style={{ height: '30px' }}>
                                                                                <p style={{
                                                                                    color: 'grey',
                                                                                    textAlign: 'start',
                                                                                    lineHeight: 1.4,
                                                                                    color: '#ec671b',
                                                                                    marginBottom: 0
                                                                                }}>{selectedLanguage?.Created}:{v?.created ? moment(v?.created).format('DD/MM/YY') : '------'}</p>
                                                                                <p style={{ fontSize: '12px' }}>{selectedLanguage?.Status}:
                                                                                    <span style={{ color: '#68E1C5' }}>
                                                                                        {
                                                                                            v?.isApproved ? ' ' + selectedLanguage?.Approved : ' ' + selectedLanguage?.Pending
                                                                                        }
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                                <CiEdit
                                                                                    onClick={() => setEdit(v)}
                                                                                    size={22} color='white' className='EditDeleteIcon' />
                                                                                <Popconfirm
                                                                                    title={`${selectedLanguage?.Delete} ${convertTitle(`${v?.name} `)} ${selectedLanguage?.Product} ?`}
                                                                                    okText={selectedLanguage?.Delete}
                                                                                    cancelText={selectedLanguage?.Cancel}
                                                                                    okButtonProps={{
                                                                                        type: 'primary',
                                                                                        className: 'form-button'
                                                                                    }}
                                                                                    cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                                                    onConfirm={() => { deleteProduct(v?._id) }}
                                                                                >{spin[v?._id] ?
                                                                                    <Spin /> :
                                                                                    <AiOutlineDelete
                                                                                        size={22} color='white' className='EditDeleteIcon' />
                                                                                    }</Popconfirm>
                                                                                {user?.supplierType !== 'shops' &&
                                                                                    <Button
                                                                                        type='primary'
                                                                                        onClick={() => v?.reservation ?
                                                                                            gotoReservationSetting(v)
                                                                                            :
                                                                                            (setReservationForm(true), setProductDetail(v))}
                                                                                        style={{ height: '30px', width: '85px', padding: '1.5px' }} className='new-delete-Btn'
                                                                                    >{selectedLanguage?.RESERVATION}</Button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <div style={{ height: '280px', width: '100%', marginTop: '10px' }}>
                                                            <div style={{ textAlign: 'center' }}>
                                                                <img style={{ height: '200px', width: '200px', padding: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }} src={NoCards} />
                                                            </div>
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                    <div
                                        className='DesktopTable sidebar-scroll_class'
                                        style={{
                                            marginTop: 8,
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            overflowX: 'scroll'
                                        }}>
                                        {loading ?
                                            [1, 2, 3, 4].map((v, i) => {
                                                return (<Card
                                                    key={i}
                                                    loading={loading}
                                                    bordered={false}
                                                    className='loading-card-style'
                                                    style={{
                                                        height: 380,
                                                        width: '285px',
                                                        minWidth: '285px',
                                                    }}
                                                />)
                                            }) :
                                            filteredArray?.length ?
                                                filteredArray?.map((v, i) => {
                                                    return (
                                                        <div
                                                            key={i}
                                                            style={{
                                                                height: 380,
                                                                width: '285px',
                                                                minWidth: '285px',
                                                                borderRadius: '4px',
                                                                margin: '4px',
                                                                marginRight: '10px',
                                                                boxShadow: ' 0 4px 5px 0 rgba(0, 0, 0, 0.3)',
                                                                cursor: 'pointer'
                                                            }}>
                                                            <div style={{ textAlign: 'center', margin: '8px' }}>
                                                                <img src={v?.imageUrl} style={{ height: '130px', width: '100%', maxWidth: '100%', borderRadius: '4px' }} />
                                                            </div>
                                                            <div style={{ textAlign: 'center', margin: '8px' }}>
                                                                <div className='div-flex-back-name' style={{ justifyContent: 'space-between', height: '30px' }}>
                                                                    <div>
                                                                        <p style={{
                                                                            fontSize: 18,
                                                                            fontWeight: 'bold',
                                                                            color: 'black',
                                                                            width: '160px',
                                                                            textAlign: 'start',
                                                                            whiteSpace: 'nowrap',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis'
                                                                        }}>{v?.name || '------'}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p style={{ color: '#ec671b' }}>€{v?.productType?.feeCost ? v?.productType?.feeCost : 0}</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <p style={{
                                                                        color: 'grey',
                                                                        textAlign: 'start',
                                                                        lineHeight: 1.4,
                                                                        height: '42px',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        lineBreak: 'anywhere',
                                                                        marginBottom: 0
                                                                    }}>
                                                                        {v?.description || '-------------------------------'}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p style={{
                                                                        color: 'grey',
                                                                        textAlign: 'start',
                                                                        lineHeight: 1.4,
                                                                        marginBottom: 0
                                                                    }}>
                                                                        {selectedLanguage?.Topic}: {v?.topicId?.topicName}
                                                                    </p>
                                                                    <p style={{
                                                                        textAlign: 'start',
                                                                        lineHeight: 1.4,
                                                                        color: '#ec671b',
                                                                        marginBottom: 0
                                                                    }}>
                                                                        {selectedLanguage?.Sold}: {v?.sold}
                                                                        {/* {calcSoldProducts(v?.reservation?.settings || []) || 0} */}
                                                                    </p>
                                                                    <p style={{
                                                                        color: 'grey',
                                                                        textAlign: 'start',
                                                                        lineHeight: 1.4,
                                                                        marginBottom: 0
                                                                    }}>
                                                                        {selectedLanguage?.PrivateCode}:{v?.productCode || '------'}
                                                                    </p>
                                                                </div>
                                                                <div className='div-flex-back-name' style={{ justifyContent: 'space-between', height: '30px' }}>
                                                                    <div>
                                                                        <p style={{
                                                                            color: 'grey',
                                                                            textAlign: 'start',
                                                                            lineHeight: 1.4,
                                                                            color: '#ec671b',
                                                                            marginBottom: 0
                                                                        }}>{selectedLanguage?.Created}:{v?.created ? moment(v?.created).format('DD/MM/YY') : '------'}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p style={{ fontSize: '12px' }}>{selectedLanguage?.Status}:
                                                                            <span style={{ color: '#68E1C5' }}>
                                                                                {
                                                                                    v?.isApproved ? ' ' + selectedLanguage?.Approved : ' ' + selectedLanguage?.Pending
                                                                                }
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='div-flex-back-name' style={{ justifyContent: 'space-between', margin: 8, marginTop: 0 }}>
                                                                <div style={{ width: '80px', display: 'flex', justifyContent: 'space-between' }}>
                                                                    <CiEdit
                                                                        onClick={() => setEdit(v)}
                                                                        size={22} color='white' className='EditDeleteIcon' />
                                                                    <Popconfirm
                                                                        title={`${selectedLanguage?.Delete} ${convertTitle(`${v?.name} `)} ${selectedLanguage?.Product} ?`}
                                                                        okText={selectedLanguage?.Delete}
                                                                        cancelText={selectedLanguage?.Cancel}
                                                                        okButtonProps={{
                                                                            type: 'primary',
                                                                            className: 'form-button'
                                                                        }}
                                                                        cancelButtonProps={{ style: { borderRadius: 4 } }}
                                                                        onConfirm={() => { deleteProduct(v?._id) }}
                                                                    >{spin[v?._id] ?
                                                                        <Spin /> :
                                                                        <AiOutlineDelete
                                                                            size={22} color='white' className='EditDeleteIcon' />
                                                                        }</Popconfirm>
                                                                </div>
                                                                {user?.supplierType !== 'shops' &&
                                                                    <Button
                                                                        type='primary'
                                                                        onClick={() => v?.reservation ?
                                                                            gotoReservationSetting(v)
                                                                            :
                                                                            (setReservationForm(true), setProductDetail(v))}
                                                                        style={{ height: '30px', width: '85px', padding: '1.5px' }} className='new-delete-Btn'
                                                                    >{selectedLanguage?.RESERVATION}</Button>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }) :
                                                <div style={{ height: '280px', width: '100%', marginTop: '10px' }}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img style={{ height: '200px', width: '200px', padding: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }} src={NoCards} />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div >
                }
            </div >
        </>

    )

}

export default Products

