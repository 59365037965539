import { ALL_GUESTS, SEGMENT_MESSAGES, SUBSCRIPTIONS, POSTAL_CARD, INVOICES, USER_SEGMENTS, ALL_SUBSCRIBE_COUPONS, SAVINGS, LOTTERIES_SAVINGS, FORMS, FORM_REPORTS, GET_COUPONS, GET_NOTIFICATIONS, GET_CARDS, GET_ADVERTISEMENT, WALLET, ALL_SUBSCRIBE_LOTTERY, USER_PENDING_GROUPS, USER_JOINED_GROUPS, PRODUCT_FILTER, VOUCHERS, PAYMENTS, BU_INVOICES, RATES, ALL_SUBSCRIBE_GIFTCARDS, USER_FAQ, BU_PRODUCT } from '../types'

const addAllInvoices = (invoices) => {
    return {
        type: INVOICES,
        invoices
    }
}

const addAllPayments = (payments) => {
    return {
        type: PAYMENTS,
        payments
    }
}

const addAllVouchers = (vouchers) => {
    return {
        type: VOUCHERS,
        vouchers
    }
}

const addFilter = (filter) => {
    return {
        type: PRODUCT_FILTER,
        filter
    }
}

const addAllBuInvoices = (bu_invoices) => {
    return {
        type: BU_INVOICES,
        bu_invoices
    }
}

const addAllSubscriptions = (subscriptions) => {
    return {
        type: SUBSCRIPTIONS,
        subscriptions
    }
}
const setRates = (rates) => {
    return {
        type: RATES,
        rates
    }
}
const addUserJoinedGroups = (joined) => {
    return {
        type: USER_JOINED_GROUPS,
        joined
    }
}
const addUserPendingGroups = (pending) => {
    return {
        type: USER_PENDING_GROUPS,
        pending
    }
}
const addAllLotterySubscriptionDetail = (lotteryDetail) => {
    return {
        type: ALL_SUBSCRIBE_LOTTERY,
        lotteryDetail
    }
}
const addAllGiftCardSubscriptionDetail = (giftCardDetail) => {
    return {
        type: ALL_SUBSCRIBE_GIFTCARDS,
        giftCardDetail
    }
}

const addUserFaq = (userFaq) => {
    return {
        type: USER_FAQ,
        userFaq
    }
}

const addAllBuProducts = (buProducts) => {
    return {
        type: BU_PRODUCT,
        buProducts
    }
}


export {

    addAllInvoices,
    addFilter,
    addAllVouchers,
    addAllPayments,
    addAllBuInvoices,
    addAllSubscriptions,
    setRates,
    addUserJoinedGroups,
    addUserPendingGroups,
    addAllLotterySubscriptionDetail,
    addAllGiftCardSubscriptionDetail,
    addUserFaq,
    addAllBuProducts
}