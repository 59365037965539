import { ALL_GUESTS, SEGMENT_MESSAGES, SUBSCRIPTIONS, POSTAL_CARD, INVOICES, USER_SEGMENTS, ALL_SUBSCRIBE_COUPONS, SAVINGS, LOTTERIES_SAVINGS, FORMS, FORM_REPORTS, GET_COUPONS, GET_NOTIFICATIONS, GET_CARDS, GET_ADVERTISEMENT, WALLET, ALL_SUBSCRIBE_LOTTERY, USER_JOINED_GROUPS, USER_PENDING_GROUPS, PRODUCT_FILTER, VOUCHERS, PAYMENTS, BU_INVOICES, RATES, ALL_SUBSCRIBE_GIFTCARDS, USER_FAQ, BU_PRODUCT, REMOVE_USER } from '../types'

const reducer = (state = {}, action) => {
    switch (action.type) {

        case INVOICES: {
            return { ...state, invoices: action.invoices }
        }
        case PAYMENTS: {
            return { ...state, payments: action.payments }
        }
        case VOUCHERS: {
            return { ...state, vouchers: action.vouchers }
        }
        case PRODUCT_FILTER: {
            return { ...state, filter: action.filter }
        }
        case BU_INVOICES: {
            return { ...state, bu_invoices: action.bu_invoices }
        }
        case SUBSCRIPTIONS: {
            return { ...state, subscriptions: action.subscriptions }
        }
        case RATES: {
            return { ...state, rates: action.rates }
        }
        case USER_JOINED_GROUPS: {
            return { ...state, joined: action.joined }
        }
        case USER_PENDING_GROUPS: {
            return { ...state, pending: action.pending }
        }
        case ALL_SUBSCRIBE_LOTTERY: {
            return { ...state, lotteryDetail: action.lotteryDetail }
        }
        case ALL_SUBSCRIBE_GIFTCARDS: {
            return { ...state, giftCardDetail: action.giftCardDetail }
        }
        case USER_FAQ: {
            return { ...state, userFaq: action.userFaq }
        }
        case BU_PRODUCT: {
            return { ...state, buProducts: action.buProducts }
        }
        case REMOVE_USER: {
            return {}
        }
        default: {
            return state
        }
    }
}

export default reducer