const adminApi = `/api/admin`
const authApi = `/api/user`
const getApi = `/api/get`
const postApi = `/api/post`

const AUTH = {
    LOGIN: `${authApi}/login`,
    SIGNUP: `${authApi}/signup`,
    CHANGE_PASSWORD: `${authApi}/change-password`,
    CHANGE_ROLE: `${authApi}/change-role`,
    UPDATE_SETTINGS: `${authApi}/update-settings`,
    DELETE_ACCOUNT: `${authApi}/delete-account`,
    FORGOT_PASSWORD: `${authApi}/forgot-password`,
    UPDATE_PASSWORD: `${authApi}/update-password`,
    ACTIVATE_USER: `${authApi}/activate-user`

}

const GET = {
    USER_BY_ID: `${getApi}/get-user`,
    VERIFY_TOKEN: `${getApi}/verifytoken`,
    VERIFY_EMAIL: `${getApi}/verifyemail`,
    GET_PRODUCTS: `${getApi}/get-products`,
    GET_BU_PRODUCTS: `${getApi}/get-bu-products`,
    GET_RESERVATION: `${getApi}/get-reservation`,
    GET_SINGLE_PRODUCT_RESERVATION: `${getApi}/get-single-product-reservation`,
    GET_PRODUCT_TYPES: `${getApi}/get-product-types`,
    ALL_BUSINESS_USERS: `${getApi}/get-all-bu-users`,
    GET_CITIES: `${getApi}/get-cities`,
    PAYMENT_STATUS: `${getApi}/get-payment-status`,
    INVOICES: `${getApi}/get-invoices`,
    GET_GUEST_VOUCHERS: `${getApi}/get-guest-vouchers`,
    GET_BU_VOUCHERS: `${getApi}/get-bu-Vouchers`,
    GET_BU_PAYMENTS: `${getApi}/get-bu-payments`,
    ALL_GUEST_USERS: `${getApi}/get-all-guest-users`,
    GET_BU_PAYMENT_REPORTS: `${getApi}/get-payment-report`,
    GET_RATE_LIST: `${getApi}/get-rate-list`,
    GET_ALL_PRODUCTS: `${getApi}/get-all-products`,
    GET_ALL_USER_COUPONS: `${getApi}/get-all-user-coupons`,
    GET_PRODUCT_BY_ID: `${getApi}/get-product-by-id`,
    GET_BU_INVOICES: `${getApi}/get-bu-invoices`, // city and province in body
    GET_TOPICS: `${getApi}/get-topics`, // city and province in body
    GET_REFUND_INVOICES: `${getApi}/get-refund-invoices`, // city and province in body
    GET_COUNTRY_TAX: `${getApi}/get-country-tax`,
    ACTIVE_SUBSCRIPTION: `${getApi}/get-active-subscriptions`,
    USER_JOINED_GROUPS: `${getApi}/user-joined-groups`,
    USER_PENDING_GROUPS: `${getApi}/user-pending-groups`,
    GET_USER_LOTTERIES: `${getApi}/get-lottery-by-userId`,
    GET_USER_DOUBLE_CHANCE_INVOICE: `${getApi}/get-user-double-chance-invoice`,
    LOTTERY_SUBSCRIPTION: `${getApi}/get-lottery-subscription`,
    ALL_LOTTERY_DETAIL: `${getApi}/get-all-lottery-detail`,
    GET_GIFT_CARD_USERS: `${getApi}/get-all-gift-cards-settelment-users`,//POST userId
    GET_ALL_USER_GIFT_CARDS: `${getApi}/get-all-user-gift-cards`,//param userId
    GET_ALL_BUUSER_GIFT_CARDS: `${getApi}/get-gift-cards-for-bu-users`,//param userId
    GIFT_CARD_SUBSCRIPTION: `${getApi}/get-gift-card-subscription`,
    GET_USER_GIFT_CARD_CLAIM_REQUEST: `${getApi}/get-user-gift-cards-claim-req`,//POST userId
    GET_GUEST_UPDATED_GIFT_CARDS: `${getApi}/get-guest-updated-gift-cards`,//PARAMS userId
    ALL_GIFTCARD_DETAIL: `${getApi}/get-all-giftCard-detail`,
}

const POST = {
    GET_USER_ALL_LOTTERIES: `${postApi}/get-user-all-lotteries`,
    CREATE_PRODUCT: `${postApi}/create-product`,
    EDIT_PRODUCT: `${postApi}/edit-product`,
    DELETE_PRODUCT: `${postApi}/delete-product`,
    CREATE_RESERVATION: `${postApi}/create-reservation`,
    UPDATE_RESERVATION_SETTING: `${postApi}/update-reservation-setting`,
    COPY_RESERVATION_SETTIN: `${postApi}/copy-reservation-setting`,
    GET_PRODUCT_FOR_GUEST: `${postApi}/get-product-for-guest`, // city and province in body
    APPROVE_PRODUCT: `${postApi}/approve-product`, // city and province in body
    CHANGE_PRIVATE_CODE: `${postApi}/change-private-code`, // city and province in body
    ADD_SECRET_CODE: `${postApi}/add-secret-code`, // city and province in body
    GET_PRIV_PRODUCTS: `${postApi}/get-private-products`, // city and province in body
    ADD_FAVOURITE_PRODUCT: `${postApi}/add-favourite`, // city and province in body
    GUEST_BUY_PRODUCT: `${postApi}/guest-buy-product`, // city and province in body
    ACCEPTVOUCHERS: `${postApi}/accept-vouchers`, // city and province in body
    VERIFYVOUCHER: `${postApi}/verify-voucher`, // city and province in body
    CREATE_REVIEW: `${postApi}/create-review`, // Review
    USER_BUY_COUPONS: `${postApi}/user-buy-coupons`, // Review
    CREATE_REVIEW_ANSWER: `${postApi}/create-review-answer`, // /create-review-answer
    VALIDATE_COUPON: `${postApi}/validate-coupon`, // /create-review-answer
    GET_RECOMMENDATIONS: `${postApi}/get-recommendations`, // /create-review-answer
    VOUCHER_CANCELLATION: `${postApi}/voucher-cancellation`, // /create-review-answer
    UPDATE_SUBSCRIPTION: `${postApi}/update-subscription`,
    CREATE_SUBSCRIPTION: `${postApi}/create-subscription`,
    GET_SHOPS: `${postApi}/get-shops`, // /create-review-answer
    EXTEND_SUBSCRIPTION_EXPIRY: `${postApi}/extend-subscription-expiry`,
    GET_PROMOTIONS: `${postApi}/get-promotions`, // /create-review-answer
    POST_COUNTRY_TAX: `${postApi}/post-country-tax`, // /create-review-answer
    EDIT_COUNTRY_TAX: `${postApi}/edit-country-tax`, // /create-review-answer
    JOIN_GROUP: `${postApi}/join-group`,
    DELETE_LOTTERY_PRIZE: `${postApi}/delete-lottery`,
    SET_LOTTERY_PRIZE: `${postApi}/set-lottery-prize`,
    UPDATE_LOTTERY_PRIZE: `${postApi}/update-lottery`,
    GET_LOTTERY_PRIZE_WINNERS: `${postApi}/get-lottery-prize-winners`,
    ACCEPT_WITH_DRAW_REQUEST: `${postApi}/accept-with-draw-request`,
    GET_WITH_DRAW_REQUEST_USER: `${postApi}/get-with-draw-request-user`,
    GET_LOTTERY_SAVINGS: `${postApi}/get-lottery-savings`,
    REMOVE_DOUBLE_CHANCES_SUBSCRIPTION: `${postApi}/remove-double-chances`,
    CLAIM_LOTTERY_DETAIL: `${postApi}/claim-lottery-detail`,
    GET_PRIZES_FOR_GUEST: `${postApi}/get-prizes-for-guest`,
    DOUBLE_CHANCES_SUBSCRIPTION: `${postApi}/double-chances`,
    CLAIM_LOTTERY: `${postApi}/claim-lottery`,
    WITH_DRAW_REQUEST: `${postApi}/with-draw-request`,
    ADD_CARD_LOTTERIES: `${postApi}/add-card-lottery`,
    VERIFY_GIFT_CARDS: `${postApi}/verify-gift-card`,
    GIFT_CARDS_ACCEPT_REQUESTS: `${postApi}/claim-req-for-gift-card`,
    CLAIM_BU_GIFT_CARD: `${postApi}/claim-bu-gift-card`,
    SET_PRIZES_OF_GIFT_CARDS: `${postApi}/set-prizes-of-gift-cards`,
    GUEST_PURCHASE_GIFT_CARDS: `${postApi}/guest-purchased-gift-card`,
    CHANGE_PRIZE_OF_GIFT_CARDS: `${postApi}/change-prize-of-admin-gift-card`,
    ADD_MORE_GIFTCARDS: `${postApi}/add-gift-cards`,
}

const ADMIN = {
    LOGIN: `${adminApi}/login`,
    GET_PRODUCT_TYPES: `${adminApi}/get-product-types`, // get , params id
    CREATE_PRODUCT_TYPES: `${adminApi}/create-product-types`, // post
    EDIT_PRODUCT_TYPES: `${adminApi}/edit-product-types`, // post
    DELETE_PRODUCT_TYPES: `${adminApi}/delete-product-types`, // post
    ACTIVATE_PRODUCT: `${adminApi}/activate-product`, // post
    GET_ADMIN_VOUCHER: `${adminApi}/get-admin-voucher`, // post
    GET_ADMIN_PAYMENTS: `${adminApi}/get-admin-payments`, // post
    GET_USER_DETAILS: `${adminApi}/get-user-details`, // post
    CHANGE_PAYMENT_STATUS: `${adminApi}/change-payment-status`, // post
    GET_PAYMENT_REPORT: `${adminApi}/get-payment-report`, // post
    GET_ALL_PRODUCTS: `${adminApi}/get-all-products`, // get
    CREATE_ADMIN_COUPONS: `${adminApi}/create-admin-coupons`, // post
    GET_ADMIN_COUPONS: `${adminApi}/get-admin-coupons`, // get
    GET_ALL_RATES: `${adminApi}/get-all-rates`, // get
    UPDATE_ALL_RATES: `${adminApi}/update-rates`, // post
    UPDATE_PAYMENT_STATUS: `${adminApi}/update-payment-status`, // post
    UPDATE_PRINT_STATUS: `${adminApi}/update-print-status`, // post
    CHANGE_REFUND_STATUS: `${adminApi}/change-refund-status`, // post
    HIDE_PRODUCT_REVIEW: `${adminApi}/hide-product-review`, // post
    SHOW_PRODUCT_REVIEW: `${adminApi}/show-product-review`, // post
    HIDE_SINGLE_REVIEW: `${adminApi}/hide-single-review`, // post
    SHOW_SINGLE_REVIEW: `${adminApi}/show-single-review`, // post
    CREATESUBADMIN: `${adminApi}/create-sub-admin`, // post
    GETALLSUBADMINS: `${adminApi}/get-all-sub-admins`, // gett
    ACTIVATESUBADMINS: `${adminApi}/activate-sub-admins`, // gett
    EDITSUBADMIN: `${adminApi}/edit-sub-admin`, // post
    GET_RATE_LIST: `${adminApi}/get-rate-list`, // get
    GET_COUPON_REPORT: `${adminApi}/get-coupon-report`, // get
    GET_SUBSCRIPTION_REPORT: `${adminApi}/get-subscription-report`, // get
    GET_FEE_REPORT: `${adminApi}/get-fee-report`, // get
    GET_GROUPS: `${adminApi}/get-group`, /* GET API */
    INVITE_USER_IN_GROUP: `${adminApi}/invite-user-in-group`,
    GET_INVITED_USERS: `${adminApi}/get-invited-users`, /* GET API */
    DELETE_GROUP: `${adminApi}/delete-group`,
    GET_BU_USERS: `${adminApi}/get-bu-users`, /* POST API */
    DELETE_INVITED_USER: `${adminApi}/delete-user-invite`, /* POST API */
    CREATE_GROUP: `${adminApi}/create-group`,
    EDIT_GROUP: `${adminApi}/edit-group`,
    EACH_MONTH_LOTTETY_PRIZE: `${adminApi}/each-month-lottery-prize`,
    GET_DOUBLE_OF_CHANCE_GUEST: `${adminApi}/get-double-chance-guest`,
    GET_LOTTERY_CARDS: `${adminApi}/get-lottery-cards`,
    EACH_MONTH_ALL_LOTTETY_CODES: `${adminApi}/each-month-all-lottery-codes`,
    GET_ALL_ADMIN_GIFT_CARDS: `${adminApi}/get-all-admin-gift-cards`,//param userId
    CREATE_ADMIN_GIFT_CARDS: `${adminApi}/create-admin-gift-cards`, /* POST API */
    CREATE_GIFT_CARD_IMAGES: `${adminApi}/create-gift-cards-images`,//POST userId
    GET_ADMIN_NOTIFICATIONS: `${adminApi}/get-all-notification`,//param userId
    GET_ALL_GIFT_CARD_REQUEST: `${adminApi}/get-all-gift-cards-request`,//param userId
    ACCEPT_GIFT_CARD_REQUET: `${adminApi}/accept-gift-card-request`,//POST userId
    GET_ALL_GIFT_CARDS_FOR_ADMIN: `${adminApi}/get-all-gift-cards-for-admin`,//param userId
    SET_ACTIVE_GIFT_CARD_IMAGE: `${adminApi}/set-active-admin-gift-card-image`, /* POST API */
    GET_GIFT_CARD_IMAGES: `${adminApi}/get-gift-cards-images`,//POST userId
    DELETE_GIFT_CARD_IMAGES: `${adminApi}/delete-gift-cards-images`,//POST userId
    UPDATE_LOTTERY_WITHDRAW_DATE: `${adminApi}/updated-lottery-withdraw-date`, /* POST API */
    ADD_NEW_FAQ: `${adminApi}/add-new-faq`, // post
    GET_ADMIN_FAQ: `${adminApi}/get-admin-faq`, // get 
    DELETE_ADMIN_FAQ: `${adminApi}/delete-admin-faq`, // post
    EDIT_FAQ_QUESTION: `${adminApi}/edit-admin-faq`, //post
}
export {
    AUTH,
    GET,
    POST,
    ADMIN
}