import React from "react";
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa'
import { TbWorldWww } from 'react-icons/tb'
import { HiMiniArrowLongRight, HiMiniNewspaper } from "react-icons/hi2";
import { SiMinutemailer } from "react-icons/si";
import { IoMdCall } from "react-icons/io";
import { allPaths } from '../../utils/constants'
const NewFooter = (props) => {
    const { user, history, selectedLanguage } = props
    return (
        <>
            <div className='footer-notification'>
                <p >{selectedLanguage?.txt_EXPERIENCEYOULLNEVERFORGET}</p>
            </div>
            <div className="new-footer-main-upper">
                <div className="new-footer-main">
                    <div className="footer-inner-box">
                        <p className="upper-footer-txt-logo">
                            CITY-DEALS
                            <span>
                                {selectedLanguage?.txt_EXPERIENCEYOULLNEVERFORGET}
                            </span>
                        </p>
                        <p className="footer-gry-para">
                            {selectedLanguage?.txt_Asmallrivernamdudenflows}
                        </p>
                        <p className="footer-icon-main">
                            <span className="footer-header-icon"><FaFacebookF size={18} /></span>
                            <span className="footer-header-icon"><FaTwitter size={18} /></span>
                            <span className="footer-header-icon"><FaInstagram size={18} /></span>
                            <span className="footer-header-icon"><TbWorldWww size={18} /></span>
                        </p>
                    </div>

                    <div className="footer-inner-box">
                        <p className="footer-div-t">{selectedLanguage?.txt_Quick_links}</p>
                        <br />
                        <span className="footer-div-span">
                            <HiMiniArrowLongRight />
                            <p>{selectedLanguage?.txt_AboutUs}</p>
                        </span>
                        <span className="footer-div-span">
                            <HiMiniArrowLongRight />
                            <p>{selectedLanguage?.txt_Services}</p>
                        </span>
                        <span className="footer-div-span">
                            <HiMiniArrowLongRight />
                            <p>{selectedLanguage?.txt_Courses}</p>
                        </span>
                        <span className="footer-div-span">
                            <HiMiniArrowLongRight />
                            <p>{selectedLanguage?.txtContactUs}</p>
                        </span>
                        <span className="footer-div-span">
                            <HiMiniArrowLongRight />
                            <p>{selectedLanguage?.txt_Blog}</p>
                        </span>
                    </div>

                    <div className="footer-inner-box">
                        <p className="footer-div-t">{selectedLanguage?.txt_Haveaquestion}</p>
                        <br />
                        <span className="footer-div-span" style={{ fontWeight: '400', alignItems: 'flex-start' }}>
                            <HiMiniNewspaper size={40} />
                            <p>{'Provinciedeals.nl, Bureau Integra bv, Wijngaardenlaan 17, 2252 XJ Voorschoten, KvK 28104652'}</p>
                        </span>
                        <span className="footer-div-span" style={{ fontWeight: '400' }}>
                            <SiMinutemailer size={20} />
                            <p>info@provinciedeals.nl </p>
                        </span>
                        <span className="footer-div-span" style={{ fontWeight: '400' }}>
                            <IoMdCall size={20} />
                            <p>111-111-991 </p>
                        </span>
                    </div>
                </div>

                {!user?._id &&
                    <p className="teacher-login0linkin-foot" onClick={() => history?.push(allPaths?.BU_LOGIN)} >
                        {selectedLanguage?.BusinessLogin}...?
                    </p>}
            </div >

            {/* <div className="inner-footer-Grey">
                <p>www.testally.com, Bureau Integra bv, Wijngaardenlaan 17, 2252 XJ Voorschoten, info@testally.com KvK 28104652</p>
            </div> */}

        </>
    )
}

export default NewFooter