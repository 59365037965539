import { Button, InputNumber, Table } from 'antd'
import 'antd/dist/antd.css'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { GET, POST } from '../../../../utils/apis'
import { assignMessage, errorMessage, errorMessageWithDuration, splitDate, successNotification, validateTime } from '../../../../utils/helpers'
const ReservationSetting = (props) => {
    const { setReservationsetting, /* reservationData, */ resId, selectedUser, getProducts, language, selectedLanguage } = props
    let tableColumn = []
    const [tableColumnState, setTableColumnState] = useState([])
    const [tableData, setTableData] = useState([])
    const [reservationData, setReservationData] = useState({})
    const [tableNewData, setTableNewData] = useState(reservationData?.settings || [])
    const [loading, setLoading] = useState(false)
    const [copyLoading, setCopyLoading] = useState(false)
    const [history, setHistory] = useState(false)
    const isEnglish = language?.language === 'english'
    console.log('reservationData', reservationData)
    useEffect(() => {
        updateColumns(tableNewData)
    }, [tableNewData])
    useEffect(() => {
        getReservation()
    }, [history])

    const updateColumns = (tableData) => {
        let data = []
        for (let v of tableData) {
            let dataRow = {}
            dataRow['date'] = v?.date
            dataRow['maximumProducts'] = v
            dataRow['soldProduct'] = v?.soldProduct
            dataRow['dateId'] = v?._id
            dataRow['timeData'] = {}
            for (let s of v?.shifts) {
                s.maximumProducts = v?.maximumProduct
                dataRow[`${s?.startTime}`] = s
            }
            data?.push(dataRow)
        }

        if (tableData?.length) {
            let dynamicColumns = reservationData && tableData?.[0]?.shifts
            for (let d of dynamicColumns) {
                let columnObj = {
                    title: validateTime(`${d?.startTime}`),
                    dataIndex: d?.startTime,
                    width: 80,
                    fixed: 'center',

                    render: (v, record) => {
                        const anotherDate = moment(splitDate(v?.date), 'YYYY-MM-DD')
                        return (
                            <InputNumber
                                style={{ width: 60 }}
                                controls={false}
                                maxLength={4}
                                pattern='[0-9]*'
                                precision={0}
                                // type='number'
                                step={1}
                                min={0}
                                max={v?.maximumProducts}
                                disabled={(moment().isAfter(anotherDate)) ? true : false}
                                defaultValue={v?.maxProduct}
                                onChange={(e) => {
                                    onInputChange('maxProduct', e, record, v?._id)
                                }} />
                        )
                    }
                }

                tableColumn.push(columnObj)
            }

            setTableData([...data])
            let tableColumns = [
                {
                    title: '',
                    width: 150,
                    dataIndex: 'date',
                    fixed: 'left',
                    render: (date) => {
                        return {
                            props: {
                                style: {
                                    background: '#ec671b',
                                    border: '2px solid #ec671b',
                                    color: 'white'
                                },
                            },
                            children: <div>{moment(splitDate(date), 'YYYY-MM-DD')?.format('ddd, DD MMM YYYY')}</div>,
                            // Mon, 08 Mar 2023
                        };
                    }
                },
                ...tableColumn,
                {
                    title: selectedLanguage?.Max,
                    width: 100,
                    dataIndex: 'maximumProducts',
                    render: (v, record) => {
                        const givenDate = moment(splitDate(v?.date), 'YYYY-MM-DD')
                        return (
                            <div style={{ width: 120 }}>
                                <InputNumber
                                    pattern='[0-9]*'
                                    type='number'
                                    min={0}
                                    precision={0}
                                    max={9999}
                                    disabled={moment().isAfter(givenDate) ? true : false}
                                    defaultValue={v?.maximumProduct}
                                    onChange={(e) => {
                                        onMaxProductChange(e, v?._id)
                                    }} />
                            </div>
                        )
                    }
                },
                {
                    title: selectedLanguage?.Total,
                    width: 100,
                    align: 'center',
                    dataIndex: 'soldProduct'
                }
            ]

            setTableColumnState([...tableColumns])
        }
    }

    const getReservation = () => {
        setLoading(true)
        axios.get(`${GET?.GET_RESERVATION}/${resId}?history=${history}`)
            .then((res) => {
                const { data } = res
                if (data?.success) {
                    setReservationData(data?.data || {})
                    setTableNewData(data?.data?.settings || [])
                }
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }
    const onInputChange = (key, text, record, id, data) => {
        const newData = [...tableNewData]
        var indexDate = _.findIndex(newData, { _id: record?.dateId })
        var indexShift = _.findIndex(newData[indexDate]?.shifts, { _id: id })
        if (text > newData[indexDate]?.maximumProduct) {
            return errorMessageWithDuration(selectedLanguage?.valueCannotBbeGreaterthanMaxofTheDay)
        }
        newData[indexDate].shifts[indexShift][key] = Number(text)
        setTableNewData(newData)
    }

    const onMaxProductChange = (key, text, record, id) => {
        const newData = [...tableNewData]
        var indexDate = _.findIndex(newData, { _id: id })

        let shifts = newData[indexDate]?.shifts

        newData[indexDate].maximumProduct = Number(text)
        setTableNewData(newData)
    }
    const onConfirm = () => {
        let obj = {
            settings: tableNewData,
            _id: reservationData?._id,
            userId: selectedUser
        }

        if (reservationData?.interval != 'none') {
            for (let v of tableNewData) {
                for (let x of v?.shifts) {
                    if (x?.maxProduct > x?.maximumProducts) {
                        return errorMessage(`Time Slot value for ${moment(splitDate(v?.date), 'YYYY-MM-DD')?.format('ddd, DD MMM YYYY')} cannot be greater than Max of the day!`)
                    }
                }
            }
        }

        setLoading(true)
        axios.post(POST?.UPDATE_RESERVATION_SETTING, obj)
            .then((res) => {
                const { data } = res
                setLoading(false)
                if (data.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                    return setTimeout(() => {
                        getProducts()
                        setReservationsetting(false)
                    }, 200)
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const copySetting = () => {
        let obj = {
            _id: reservationData?._id,
        }
        setCopyLoading(true)
        axios.post(POST?.COPY_RESERVATION_SETTIN, obj)
            .then((res) => {
                const { data } = res
                setCopyLoading(false)
                if (data?.success) {
                    successNotification(assignMessage(data?.message, language?.language))
                    return setTimeout(() => {
                        getProducts()
                        setReservationsetting(false)
                    }, 200)
                } else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
            })
            .catch((e) => {
                setCopyLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }


    let dateCond = moment(reservationData?.dateRange?.date2, 'YYYY-MM-DD').isAfter(moment()) && !(moment(reservationData?.dateRange?.date1, 'YYYY-MM-DD').isAfter(moment()))

    return (
        <div style={{ background: 'white', padding: '15px' }}>
            <div className='button-div'>
                {dateCond &&
                    <div className='btn-div'>
                        <Button
                            className='btn-div'
                            type='primary'
                            onClick={() => { setHistory(state => !state) }}
                        >
                            {history ? selectedLanguage?.txt_Current : selectedLanguage?.txt_History}
                        </Button>
                    </div>
                }
                <div className='btn-div'>
                    <Button loading={loading} className='btn-div' type='primary' onClick={onConfirm}>
                        {selectedLanguage?.ConfirmChanges}
                    </Button>
                </div>
                {!tableData?.length > 7 ?
                    <div className='btn-div'>
                        <Button type='primary' className='btn-div' loading={copyLoading} onClick={copySetting}>{selectedLanguage?.CopySetting}</Button>
                    </div>
                    :
                    null}
                <div className='btn-div'>
                    <Button
                        className='btn-div'
                        type='primary'
                        onClick={() => {
                            setReservationsetting(false)
                            getProducts()
                        }}>
                        {selectedLanguage?.GoBack}
                    </Button>
                </div>
            </div>
            <br />

            <div style={{ height: `500px` }}>
                <Table
                    rowKey='id'
                    columns={tableColumnState}
                    dataSource={loading ? [] : tableData}
                    pagination={false}
                    loading={loading}
                    scroll={{ x: 800, y: 'calc(100vh - 220px)' }}
                />
            </div>
            <br />
            <br />
        </div >
    )
}

export default ReservationSetting