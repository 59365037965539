import { Button, Checkbox, Col, Input, InputNumber, Row } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { POST } from '../../../../utils/apis'
import { allAdminData, successNotification } from '../../../../utils/helpers'

const PriceSettings = (props) => {
    const { user, selectedLanguage, rates, generalActions, adminActions } = props
    const [loading, setLoading] = useState(false)
    const [subscriptions, setSubscriptions] = useState(rates.sort((a, b) => b.subscription - a.subscription) || [])

    useEffect(() => {
        allAdminData?.getAdminRateList(adminActions, user?._id)
    }, [])

    console.log('rates', rates)

    const updateSubscription = async () => {
        setLoading(true)
        let allPromise = []
        for (var v of subscriptions) {
            allPromise.push(Promise.resolve(axios.post(POST.UPDATE_SUBSCRIPTION, v)))
        }
        await Promise.all(allPromise)
            .then(() => {
                setLoading(false)
                successNotification(selectedLanguage?.SuccssfullyUpdated)
                // getGeneralData(generalActions)
            })
    }

    const updateState = (i, k, v) => {
        if (v < 0) {
            return
        }
        subscriptions[i][k] = v
        setSubscriptions([...(subscriptions.sort((a, b) => b.subscription - a.subscription))] || [])
    }
    return (
        <div className='new-exam-main-div'>
            <div className='exam-transaprent-div'>
                <p className='screen-qoute'> {selectedLanguage?.Quote2}</p>
            </div>
            <div className='new-exam-screen-upper'>
                <div style={{ background: 'white', padding: '15px' }}>
                    <h3 className='new-exam-title'>{selectedLanguage?.PricesSettings}</h3>
                    <div style={{ background: 'white', padding: '15px' }}>
                        {subscriptions?.map((v, i) => {
                            return (
                                <Row gutter={16} key={i} style={{ marginBottom: 20 }}>
                                    <Col span={24}>
                                        {v?.subscription ?
                                            <span>
                                                <Checkbox checked={v?.active} onChange={e => updateState(i, 'active', e?.target?.checked)} />
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                {v?.subscriptionName}
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                {v?.monthDuration}
                                                &nbsp;
                                                {selectedLanguage?.text_Months}
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                            </span>
                                            :
                                            <span>
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                {v?.subscriptionName}
                                                &nbsp;
                                                &nbsp;
                                            </span>
                                        }
                                        {user?.adminType === 'sub' ?
                                            <span>
                                                <InputNumber
                                                    type='number'
                                                    className='subs-input'
                                                    value={v?.amount}
                                                    min={v?.min}
                                                    precision={2}
                                                    max={v?.max || 200}
                                                    onChange={e => updateState(i, 'amount', e)}
                                                />
                                            </span>
                                            :
                                            <span>
                                                {v?.subscriptionName !== 'Coupons' &&
                                                    <span>
                                                        <Input
                                                            prefix='min'
                                                            type='number'
                                                            className='subs-input'
                                                            value={v?.min}
                                                            min={0}
                                                            onChange={e => updateState(i, 'min', e?.target?.value)}
                                                        />
                                                        &nbsp;&nbsp;
                                                        <Input
                                                            prefix='max'
                                                            type='number'
                                                            className='subs-input'
                                                            value={v?.max}
                                                            min={0}
                                                            onChange={e => updateState(i, 'max', e?.target?.value)}
                                                        />
                                                        &nbsp;&nbsp;
                                                    </span>}
                                                <Input
                                                    type='number'
                                                    className='subs-input'
                                                    value={v?.amount}
                                                    min={0}
                                                    onChange={e => updateState(i, 'amount', e?.target?.value)}
                                                />
                                            </span>
                                        }
                                    </Col>
                                </Row>
                            )
                        })}
                        <br />
                        {subscriptions?.length ?
                            <Button
                                onClick={updateSubscription}
                                className='form-button'
                                type='primary'
                                loading={loading}
                            >
                                {selectedLanguage?.Update}
                            </Button> : null}
                        <br />
                        <br />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PriceSettings