const AllBackendMessages = {
    ApisMessage_incorrect_username_password: {
        messageEnglish: 'Oops, incorrect username or password!',
        messageNetherlands: 'Oeps, onjuiste gebruikersnaam of wachtwoord!',
        messageEgypt: 'عذرًا، اسم المستخدم أو كلمة المرور غير صحيحة!',
    },
    ApisMessage_Your_account_has_been_deactivated: {
        messageEnglish: 'Your account has been deactivated!',
        messageNetherlands: 'Uw account is gedeactiveerd!',
        messageEgypt: 'تم تعطيل حسابك!',
    },
    ApisMessage_Oops_something_went_wrong: {
        messageEnglish: 'Oops something went wrong',
        messageNetherlands: 'Oeps, er is iets misgegaan',
        messageEgypt: 'عذرًا، حدث خطأ ما',
    },
    ApisMessage_Please_provide_all_required_fields: {
        messageEnglish: 'Please provide all required fields!',
        messageNetherlands: 'Vul alle verplichte velden in!',
        messageEgypt: 'يرجى تقديم جميع الحقول المطلوبة!',
    },
    ApisMessage_admin_not_found: {
        messageEnglish: 'Admin not found!',
        messageNetherlands: 'Admin not found!',
        messageEgypt: 'Admin not found!',
    },
    ApisMessage_Product_Type_Created_Successfully: {
        messageEnglish: 'Product type created successfully!',
        messageNetherlands: 'Producttype succesvol aangemaakt!',
        messageEgypt: 'تم إنشاء نوع المنتج بنجاح!',
    },
    ApisMessage_successfully_Deleted: {
        messageEnglish: 'Successfully deleted!',
        messageNetherlands: 'Succesvol verwijderd!',
        messageEgypt: 'تم الحذف بنجاح!',
    },
    ApisMessage_Please_provide_All_Required_Fields: {
        messageEnglish: 'Please provide all required fields!',
        messageNetherlands: 'Vul alle verplichte velden in!',
        messageEgypt: 'يرجى تقديم جميع الحقول المطلوبة!',
    },
    ApisMessage_Payment_Paid_Successfully: {
        messageEnglish: 'Payment Paid Successfully!',
        messageNetherlands: 'Betaling succesvol ontvangen!',
        messageEgypt: 'تم الدفع بنجاح!',
    },
    ApisMessage_Product_Type_Edited_Successfully: {
        messageEnglish: 'Product Type edited successfully!',
        messageNetherlands: 'Producttype succesvol bewerkt!',
        messageEgypt: 'تم تحرير نوع المنتج بنجاح!',
    },
    ApisMessage_data_not_found: {
        messageEnglish: 'Data not Found!',
        messageNetherlands: 'Gegevens niet gevonden!',
        messageEgypt: 'البيانات غير موجودة!',
    },
    ApisMessage_successfully_Activated: {
        messageEnglish: 'Successfully Activated',
        messageNetherlands: 'Succesvol geactiveerd',
        messageEgypt: 'تم التنشيط بنجاح',
    },
    ApisMessage_successfully_Disactivated: {
        messageEnglish: 'Successfully Disactivated',
        messageNetherlands: 'Succesvol gedeactiveerd',
        messageEgypt: 'تم إلغاء التنشيط بنجاح',
    },
    ApisMessage_Please_provide_password: {
        messageEnglish: 'Please provide password',
        messageNetherlands: 'Vul het wachtwoord in',
        messageEgypt: 'يرجى تقديم كلمة المرور',
    },
    ApisMessage_Params_id_is_missing: {
        messageEnglish: 'Params id is missing!',
        messageNetherlands: 'Params id ontbreekt!',
        messageEgypt: 'مفتاح الهوية مفقود!',
    },
    ApisMessage_A_Group_Is_Already_Exists_With_This_Range: {
        messageEnglish: 'A Group Is Already Exists With This Range',
        messageNetherlands: 'Er bestaat al een groep met dit bereik',
        messageEgypt: 'يوجد بالفعل مجموعة بتلك النطاق',
    },
    ApisMessage_user_not_found: {
        messageEnglish: 'user not found!',
        messageNetherlands: 'Gebruiker niet gevonden!',
        messageEgypt: 'المستخدم غير موجود!',
    },
    ApisMessage_An_Admin_Already_Exists_With_This_Range: {
        messageEnglish: 'An Admin Already Exists With This Range!',
        messageNetherlands: 'Er bestaat al een beheerder met dit bereik!',
        messageEgypt: 'يوجد بالفعل مشرف بتلك النطاق!',
    },
    ApisMessage_Succssfully_Printed: {
        messageEnglish: 'Successfully Printed!',
        messageNetherlands: 'Succesvol afgedrukt!',
        messageEgypt: 'تم الطباعة بنجاح!',
    },
    ApisMessage_Invalid_Coupon: {
        messageEnglish: 'Invalid Coupon!',
        messageNetherlands: 'Ongeldige coupon!',
        messageEgypt: 'كوبون غير صالح!',
    },
    ApisMessage_This_User_Name_already_exists: {
        messageEnglish: 'This User Name already exists!',
        messageNetherlands: 'Deze gebruikersnaam bestaat al!',
        messageEgypt: 'اسم المستخدم هذا موجود بالفعل!',
    },
    ApisMessage_Admin_dont_have_access: {
        messageEnglish: 'No admin access!',
        messageNetherlands: 'Geen beheerderstoegang!',
        messageEgypt: 'لا يوجد وصول للمشرف!',
    },
    ApisMessage_Please_upload_image_only: {
        messageEnglish: 'No admin access!',
        messageNetherlands: 'Geen beheerderstoegang!',
        messageEgypt: 'لا يوجد وصول للمشرف!',
    },
    ApisMessage_Successfully_created: {
        messageEnglish: 'Successfully created!',
        messageNetherlands: 'Succesvol aangemaakt!',
        messageEgypt: 'تم إنشاء بنجاح!',
    },
    ApisMessage_Successfully_deleted: {
        messageEnglish: 'Successfully deleted!',
        messageNetherlands: 'Succesvol verwijderd!',
        messageEgypt: 'تم الحذف بنجاح!',
    },
    ApisMessage_Successfully_updated: {
        messageEnglish: 'Successfully updated!',
        messageNetherlands: 'Succesvol bijgewerkt!',
        messageEgypt: 'تم التحديث بنجاح!',
    },
    ApisMessage_User_Already_Invited: {
        messageEnglish: 'User Already Invited!',
        messageNetherlands: 'Gebruiker is al uitgenodigd!',
        messageEgypt: 'المستخدم مدعو بالفعل!',
    },
    ApisMessage_User_Invited_Successfully: {
        messageEnglish: 'User Invited Successfully!',
        messageNetherlands: 'Gebruiker succesvol uitgenodigd!',
        messageEgypt: 'تمت دعوة المستخدم بنجاح!',
    },
    ApisMessage_No_Prizes_Found: {
        messageEnglish: 'No Prizes Found!',
        messageNetherlands: 'Geen prijzen gevonden!',
        messageEgypt: 'لم يتم العثور على جوائز!',
    },
    ApisMessage_Successfully_Accepted: {
        messageEnglish: 'Successfully Accepted!',
        messageNetherlands: 'Succesvol geaccepteerd!',
        messageEgypt: 'تم قبوله بنجاح!',
    },
    ApisMessage_Advertisement_Activated_Successfully: {
        messageEnglish: 'Advertisement Activated Successfully!',
        messageNetherlands: 'Advertentie succesvol geactiveerd!',
        messageEgypt: 'تم تفعيل الإعلان بنجاح!',
    },
    ApisMessage_Successfully_Added_faq: {
        messageEnglish: 'Successfully Added faq!',
        messageNetherlands: 'FAQ succesvol toegevoegd!',
        messageEgypt: 'تم إضافة الأسئلة الشائعة بنجاح!',
    },
    ApisMessage_Email_already_in_use: {
        messageEnglish: 'Email already in use !',
        messageNetherlands: 'E-mailadres is al in gebruik',
        messageEgypt: 'البريد الإلكتروني مستخدم بالفعل',
    },
    ApisMessage_Oops_incorrect_email: {
        messageEnglish: 'incorrect Email or password!',
        messageNetherlands: 'Onjuist e-mailadres of wachtwoord!',
        messageEgypt: 'بريد إلكتروني أو كلمة مرور غير صحيحة',
    },
    ApisMessage_Oops_no_email_found: {
        messageEnglish: 'Oops, email not found!',
        messageNetherlands: 'Oeps, e-mail niet gevonden!',
        messageEgypt: 'عذرًا، البريد الإلكتروني غير موجود!',
    },
    ApisMessage_We_have_sent_you_a_verification_code: {
        messageEnglish: 'We have sent you a verification code!',
        messageNetherlands: 'We hebben je een verificatiecode gestuurd!',
        messageEgypt: 'لقد أرسلنا لك رمز التحقق!',
    },
    ApisMessage_Email_Already_Verified: {
        messageEnglish: 'Email Already Verified!',
        messageNetherlands: 'E-mail al geverifieerd!',
        messageEgypt: 'البريد الإلكتروني تم التحقق منه بالفعل!',
    },
    ApisMessage_Your_link_has_either_expired_or_become_invalid_Request_another_link: {
        messageEnglish: 'Sorry, Unable to verify your email. Your link has either expired or become invalid. Request another link!',
        messageNetherlands: 'Sorry, het is niet mogelijk om je e-mail te verifiëren. Je link is verlopen of ongeldig geworden. Vraag een nieuwe link aan!',
        messageEgypt: 'عذرًا، غير قادر على التحقق من بريدك الإلكتروني. إما أن يكون الرابط قد انتهت صلاحيته أو أصبح غير صالح. اطلب رابطًا آخر!',
    },
    ApisMessage_Either_your_link_has_expired_or_you_have_provided_incorrect_link: {
        messageEnglish: 'Sorry, Unable to verify your email. Either your link has expired or you have provided incorrect link!',
        messageNetherlands: 'Sorry, het is niet mogelijk om je e-mail te verifiëren. Je link is verlopen of je hebt een onjuiste link opgegeven!',
        messageEgypt: 'عذرًا، غير قادر على التحقق من بريدك الإلكتروني. إما أن يكون الرابط قد انتهت صلاحيته أو قدمت رابطًا غير صحيح!',
    },
    ApisMessage_Successfully_verified_your_Account_you_can_Login_Now: {
        messageEnglish: 'Successfully verified your Account. You can Login Now!',
        messageNetherlands: 'Je account is succesvol geverifieerd. Je kunt nu inloggen!',
        messageEgypt: 'تم التحقق بنجاح من حسابك. يمكنك تسجيل الدخول الآن!',
    },
    ApisMessage_Sorry_your_verification_link_has_expired_We_have_sent_another_verification_link_to_your_email: {
        messageEnglish: 'Sorry, your verification link has expired. We have sent another verification link to your email. Please check your inbox!',
        messageNetherlands: 'Sorry, je verificatielink is verlopen. We hebben een nieuwe verificatielink naar je e-mail gestuurd. Controleer je inbox!',
        messageEgypt: 'عذرًا، انتهى صلاحية رابط التحقق الخاص بك. لقد أرسلنا رابط تحقق آخر إلى بريدك الإلكتروني. يرجى التحقق من صندوق الوارد الخاص بك!',
    },
    ApisMessage_Sorry_Unable_to_verify_your_request_Either_your_link_has_expired_or_you_have_provided_incorrect_link: {
        messageEnglish: 'Sorry, Unable to verify your request. Either your link has expired or you have provided an incorrect link. Please check your inbox!',
        messageNetherlands: 'Sorry, het is niet mogelijk om je verzoek te verifiëren. Je link is verlopen of je hebt een onjuiste link opgegeven. Controleer je inbox!',
        messageEgypt: 'عذرًا، غير قادر على التحقق من طلبك. إما أن يكون الرابط قد انتهت صلاحيته أو قدمت رابطًا غير صحيح. يرجى التحقق من صندوق الوارد الخاص بك!',
    },
    ApisMessage_Sorry_Unable_to_verify_your_request_Your_link_has_either_expired_or_become_invalid: {
        messageEnglish: 'Sorry, Unable to verify your request. Your link has either expired or become invalid.',
        messageNetherlands: 'Sorry, het is niet mogelijk om je verzoek te verifiëren. Je link is verlopen of ongeldig geworden.',
        messageEgypt: 'عذرًا، غير قادر على التحقق من طلبك. إما أن يكون الرابط قد انتهت صلاحيته أو أصبح غير صالح.',
    },
    ApisMessage_Sorry_your_forgot_password_link_has_expired: {
        messageEnglish: 'Sorry, your forgot password link has expired.',
        messageNetherlands: 'Sorry, je link voor het opnieuw instellen van het wachtwoord is verlopen.',
        messageEgypt: 'عذرًا، انتهت صلاحية رابط استعادة كلمة المرور الخاص بك.',
    },
    ApisMessage_Select_a_User: {
        messageEnglish: 'Select a User!',
        messageNetherlands: 'Selecteer een gebruiker!',
        messageEgypt: 'اختر مستخدمًا!',
    },
    ApisMessage_Payment_Unsuccessful: {
        messageEnglish: 'Payment Unsuccessful!',
        messageNetherlands: 'Betaling mislukt!',
        messageEgypt: 'فشلت الدفع!',
    },
    ApisMessage_Transaction_already_completed: {
        messageEnglish: 'Transaction already completed!',
        messageNetherlands: 'Transactie is al voltooid!',
        messageEgypt: 'المعاملة تمت بالفعل!',
    },
    ApisMessage_This_quantity_is_not_available: {
        messageEnglish: 'This quantity is not available!',
        messageNetherlands: 'Deze hoeveelheid is niet beschikbaar!',
        messageEgypt: 'هذه الكمية غير متاحة!',
    },
    ApisMessage_No_Review_found: {
        messageEnglish: 'Review not found!',
        messageNetherlands: 'Recensie niet gevonden!',
        messageEgypt: 'لم يتم العثور على تقييم!',
    },
    ApisMessage_Please_Buy_Lottery_Subscription: {
        messageEnglish: 'Please Buy Lottery Subscription',
        messageNetherlands: 'Koop alstublieft een loterijabonnement',
        messageEgypt: 'يرجى شراء اشتراك في اليانصيب',
    },
    ApisMessage_Reserved_Successfully: {
        messageEnglish: 'Reserved Successfully',
        messageNetherlands: 'Succesvol gereserveerd',
        messageEgypt: 'تم الحجز بنجاح',
    },
    ApisMessage_Successfully_Copied: {
        messageEnglish: 'Successfully Copied',
        messageNetherlands: 'Succesvol gekopieerd',
        messageEgypt: 'تم النسخ بنجاح',
    },
    ApisMessage_Successfully_approved: {
        messageEnglish: 'Successfully Approved',
        messageNetherlands: 'Succesvol goedgekeurd',
        messageEgypt: 'تم الموافقة بنجاح',
    },
    ApisMessage_Successfully_added: {
        messageEnglish: 'Successfully Added',
        messageNetherlands: 'Succesvol toegevoegd',
        messageEgypt: 'تمت الإضافة بنجاح',
    },
    ApisMessage_Successfully_Added_to_favourites: {
        messageEnglish: 'Successfully added to favourites',
        messageNetherlands: 'Succesvol toegevoegd aan favorieten',
        messageEgypt: 'تمت الإضافة بنجاح إلى المفضلة',
    },
    ApisMessage_Quantity_cannot_be_zero: {
        messageEnglish: 'Quantity cannot be zero',
        messageNetherlands: 'Hoeveelheid kan niet nul zijn',
        messageEgypt: 'الكمية لا يمكن أن تكون صفر',
    },
    ApisMessage_No_voucher_found: {
        messageEnglish: 'No voucher found',
        messageNetherlands: 'Geen voucher gevonden',
        messageEgypt: 'لم يتم العثور على قسيمة',
    },
    ApisMessage_Invalid_voucher_code: {
        messageEnglish: 'Invalid voucher code',
        messageNetherlands: 'Ongeldige vouchercode',
        messageEgypt: 'رمز القسيمة غير صالح',
    },
    ApisMessage_This_voucher_belongs_to_your_business: {
        messageEnglish: 'This voucher belongs to your business',
        messageNetherlands: 'Deze voucher behoort tot uw bedrijf',
        messageEgypt: 'تعود هذه القسيمة إلى عملك',
    },
    ApisMessage_Voucher_accepted_successfully: {
        messageEnglish: 'Voucher accepted successfully',
        messageNetherlands: 'Voucher succesvol geaccepteerd',
        messageEgypt: 'تم قبول القسيمة بنجاح',
    },
    ApisMessage_Review_successfully_submitted: {
        messageEnglish: 'Review successfully submitted',
        messageNetherlands: 'Recensie succesvol ingediend',
        messageEgypt: 'تم تقديم التقييم بنجاح',
    },
    ApisMessage_File_sending_error: {
        messageEnglish: 'File sending error',
        messageNetherlands: 'Fout bij het verzenden van het bestand',
        messageEgypt: 'خطأ في إرسال الملف',
    },
    ApisMessage_Successfully_answered: {
        messageEnglish: 'Successfully answered',
        messageNetherlands: 'Succesvol beantwoord',
        messageEgypt: 'تم الرد بنجاح',
    },
    ApisMessage_Invalid_Coupon_Code: {
        messageEnglish: 'Invalid Coupon Code',
        messageNetherlands: 'Ongeldige couponcode',
        messageEgypt: 'رمز الكوبون غير صالح',
    },
    ApisMessage_Invalid_Lottery_Code: {
        messageEnglish: 'Invalid lottery Code',
        messageNetherlands: 'Ongeldige loterijcode',
        messageEgypt: 'رمز اليانصيب غير صالح',
    },
    ApisMessage_This_Coupon_has_been_Claimed_already: {
        messageEnglish: 'This Coupon has been Claimed already',
        messageNetherlands: 'Deze coupon is al geclaimd',
        messageEgypt: 'تم استيفاء هذا الكوبون بالفعل',
    },
    ApisMessage_This_Coupon_is_not_Valid_for_this_Product: {
        messageEnglish: 'This Coupon is not Valid for this Product',
        messageNetherlands: 'Deze coupon is niet geldig voor dit product',
        messageEgypt: 'هذا الكوبون غير صالح لهذا المنتج',
    },
    ApisMessage_The_Amount_of_Coupon_is_Greater_than_Maximum_Discount: {
        messageEnglish: 'The Amount of Coupon is Greater than Maximum Discount',
        messageNetherlands: 'Het bedrag van de coupon is groter dan de maximale korting',
        messageEgypt: 'مبلغ الكوبون أكبر من الحد الأقصى للخصم',
    },
    ApisMessage_Successfully_Validated: {
        messageEnglish: 'Successfully Validated',
        messageNetherlands: 'Succesvol gevalideerd',
        messageEgypt: 'تم التحقق بنجاح',
    },
    ApisMessage_Provide_Bank_Details_in_your_Profile_Settings: {
        messageEnglish: 'Provide Bank Details in your Profile Settings',
        messageNetherlands: 'Geef bankgegevens op in uw profielinstellingen',
        messageEgypt: 'قدم تفاصيل البنك في إعدادات ملفك الشخصي',
    },
    ApisMessage_Reservation_Cancelled_Successfully: {
        messageEnglish: 'Reservation Cancelled Successfully',
        messageNetherlands: 'Reservering succesvol geannuleerd',
        messageEgypt: 'تم إلغاء الحجز بنجاح',
    },
    ApisMessage_Lottery_claimed_Successfully: {
        messageEnglish: 'Lottery claimed Successfully',
        messageNetherlands: 'Loterij succesvol geclaimd',
        messageEgypt: 'تم استيفاء اليانصيب بنجاح',
    },
    ApisMessage_reviews_can_see_only_admin: {
        messageEnglish: 'Reviews can be seen only by admin',
        messageNetherlands: 'Recensies kunnen alleen worden gezien door de beheerder',
        messageEgypt: 'يمكن رؤية التقييمات فقط من قبل المشرف',
    },
    ApisMessage_reviews_available_for_every_one: {
        messageEnglish: 'Reviews available for everyone',
        messageNetherlands: 'Recensies beschikbaar voor iedereen',
        messageEgypt: 'التقييمات متاحة للجميع',
    },
    ApisMessage_Review_to_be_displayed: {
        messageEnglish: 'Review to be displayed',
        messageNetherlands: 'Recensie wordt weergegeven',
        messageEgypt: 'سيتم عرض التقييم',
    },
    ApisMessage_Review_to_be_hide: {
        messageEnglish: 'Review to be hidden',
        messageNetherlands: 'Recensie moet verborgen worden',
        messageEgypt: 'ستتم إخفاء التقييم',
    },
    ApisMessage_Group_Joined_Successfully: {
        messageEnglish: 'Group Joined Successfully',
        messageNetherlands: 'Groep succesvol toegetreden',
        messageEgypt: 'تم الانضمام إلى المجموعة بنجاح',
    },
    ApisMessage_Incorrect_Password: {
        messageEnglish: 'Incorrect Password',
        messageNetherlands: 'Onjuist wachtwoord',
        messageEgypt: 'كلمة المرور غير صحيحة',
    },
    ApisMessage_Not_Accessiable_Subscription: {
        messageEnglish: 'Not Accessible Subscription',
        messageNetherlands: 'Niet toegankelijk abonnement',
        messageEgypt: 'الاشتراك غير قابل للوصول',
    },
    ApisMessage_No_Subscription_Found_For_Card_Lottery: {
        messageEnglish: 'No Subscription Found For Card Lottery',
        messageNetherlands: 'Geen abonnement gevonden voor kaartloterij',
        messageEgypt: 'لم يتم العثور على اشتراك لليانصيب بالبطاقة',
    },
    ApisMessage_Please_Buy_Lottery: {
        messageEnglish: 'Please Buy Lottery',
        messageNetherlands: 'Koop alsjeblieft een loterij',
        messageEgypt: 'يرجى شراء تذكرة اليانصيب',
    },
    ApisMessage_Please_Join_Group_To_create_Public_Lotteries: {
        messageEnglish: 'Please Join Group To create Public Lotteries',
        messageNetherlands: 'Sluit je aan bij de groep om openbare loterijen te maken',
        messageEgypt: 'يرجى الانضمام إلى المجموعة لإنشاء سحوبات عامة',
    },
    ApisMessage_Successfully_Set_Lottery_Prizes: {
        messageEnglish: 'Successfully Set Lottery Prizes',
        messageNetherlands: 'Succesvol de prijzen van de loterij ingesteld',
        messageEgypt: 'تم تعيين جوائز اليانصيب بنجاح',
    },
    ApisMessage_Subscription_de_Activated_Successfully: {
        messageEnglish: 'Subscription de-Activated Successfully',
        messageNetherlands: 'Abonnement succesvol gedeactiveerd',
        messageEgypt: 'تم إلغاء تنشيط الاشتراك بنجاح',
    },
    ApisMessage_WithDraw_Request_Already_Submitted: {
        messageEnglish: 'Withdrawal Request Already Submitted',
        messageNetherlands: 'Opnameverzoek is al ingediend',
        messageEgypt: 'تم تقديم طلب السحب بالفعل',
    },
    ApisMessage_No_Lottery_Found: {
        messageEnglish: 'No Lottery Found',
        messageNetherlands: 'Geen loterij gevonden',
        messageEgypt: 'لم يتم العثور على سحب',
    },
    ApisMessage_There_is_a_Pending_Request_with_this_User: {
        messageEnglish: 'There is a Pending Request with this User',
        messageNetherlands: 'Er is een in behandeling zijnd verzoek bij deze gebruiker',
        messageEgypt: 'هناك طلب معلق مع هذا المستخدم',
    },
    ApisMessage_Successfully_Submitted_Request: {
        messageEnglish: 'Successfully Submitted Request ',
        messageNetherlands: 'Verzoek succesvol ingediend ',
        messageEgypt: 'تم تقديم الطلب بنجاح',
    },
    ApisMessage_Dont_have_enough_Gift_Cards_Please_purchase_the_Gift_Cards: {
        messageEnglish: "Don't have enough Gift Cards, Please purchase the Gift Cards",
        messageNetherlands: "Niet genoeg cadeaubonnen, koop alsjeblieft de cadeaubonnen",
        messageEgypt: "ليس لديك ما يكفي من بطاقات الهدايا، يرجى شراء بطاقات الهدايا",
    },
    ApisMessage_No_Gift_Card_Found: {
        messageEnglish: 'No Gift Card Found',
        messageNetherlands: 'Geen cadeaubon gevonden',
        messageEgypt: 'لم يتم العثور على بطاقة هدايا',
    },
    ApisMessage_Invalid_Gift_Card_Code: {
        messageEnglish: 'Invalid Gift Card Code',
        messageNetherlands: 'Ongeldige cadeauboncode',
        messageEgypt: 'رمز بطاقة الهدية غير صالح',
    },
    ApisMessage_No_Gift_card_found_against_this_code: {
        messageEnglish: 'No Gift card found against this code',
        messageNetherlands: 'Geen cadeaubon gevonden voor deze code',
        messageEgypt: 'لم يتم العثور على بطاقة هدايا مقابل هذا الرمز',
    },
    ApisMessage_Card_Accepted_Successfully: {
        messageEnglish: 'Card Accepted Successfully',
        messageNetherlands: 'Kaart succesvol geaccepteerd',
        messageEgypt: 'تم قبول البطاقة بنجاح',
    },
}

export default AllBackendMessages