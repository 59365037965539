import { message, notification } from 'antd'
import axios from 'axios'
import momentTz from 'moment-timezone'
import PDFDocument from '@react-pdf/pdfkit'
import blobStream from 'blob-stream'
import draftToHtml from 'draftjs-to-html'
import ReactHtmlParser from 'react-html-parser'
import QRCode from 'qrcode'
import allPaths from '../Config/paths'
import chunk from 'lodash/chunk'
import { AUTH, GET, ADMIN } from './apis'
import moment from 'moment-timezone'
import _ from 'lodash'
import EnglishLanguage from '../../src/Languages/EnglishLanguage.json'
import NlLanguage from '../../src/Languages/NetherlandsLanguage.json'
import EgyptLanguage from '../../src/Languages/EgyptLanguage.json'
import AllBackendMessages from './AllBackendMessages'


const requiredMessage = (value) => `Please input your ${value}!`

const inputPlace = (value) => `Input your ${value} Here...!`

const datePlace = (value) => `Input number of days to send (${value}) Here...!`

const setActiveMenu = (path) => path === allPaths?.PAYMENTS ? allPaths?.PAYMENTS : path === allPaths?.VOUCHERS ? allPaths?.VOUCHERS : path === allPaths?.INVOICES ? allPaths?.INVOICES : path === allPaths?.BUPAYMENTS ? allPaths?.BUPAYMENTS : path === allPaths?.BUVOUCHERS ? allPaths?.BUVOUCHERS : path === allPaths?.SUBSCRIPTION ? allPaths?.SUBSCRIPTION : path === allPaths?.BUINVOICES ? allPaths?.BUINVOICES : path === allPaths?.HOME ? allPaths?.HOME : (path === allPaths?.BU_PRODUCT || path === allPaths?.HOME) ? allPaths?.BU_PRODUCT : path === allPaths?.PRIV_PRODUCT ? allPaths?.PRIV_PRODUCT : path === allPaths?.COUPON ? allPaths?.COUPON : path === allPaths.GROUP ? allPaths.GROUP : 4

const setActiveAdminMenu = (path) => path === allPaths.ADMIN_USERS ? allPaths.ADMIN_USERS : path === allPaths.ADMIN_SUBADMINS ? allPaths.ADMIN_SUBADMINS : path === allPaths.ADMIN_TYPES ? allPaths.ADMIN_TYPES : path === allPaths?.ADMIN_PRODUCT ? allPaths?.ADMIN_PRODUCT : path === allPaths?.ADMINVOUCHERS ? allPaths?.ADMINVOUCHERS : path === allPaths?.ADMINPAYMENTS ? allPaths?.ADMINPAYMENTS : path === allPaths?.ADMINBUINVOICES ? allPaths?.ADMINBUINVOICES : path === allPaths?.ADMINGUESTINVOICES ? allPaths?.ADMINGUESTINVOICES : path === allPaths.PAYMENT_REPORT ? allPaths.PAYMENT_REPORT : path === allPaths?.COUPONS_REPORT ? allPaths?.COUPONS_REPORT : path === allPaths?.SUBSCRIPTION_REPORT ? allPaths?.SUBSCRIPTION_REPORT : path === allPaths?.FEE_REPORT ? allPaths?.FEE_REPORT : path === allPaths.ADMIN_COUPON ? allPaths.ADMIN_COUPON : path === allPaths.ADMIN_PRICE_SETTINGS ? allPaths.ADMIN_PRICE_SETTINGS : path === allPaths.ADMIN_GROUP ? allPaths.ADMIN_GROUP : path === allPaths.ADMIN_LOTTERY ? allPaths.ADMIN_LOTTERY : 0

const setKeyMenu = (path) =>
    (path === allPaths?.ADMIN_TYPES || path === allPaths?.ADMIN_PRODUCT || path === allPaths?.ADMIN_PRICE_SETTINGS) ? allPaths.ADMIN_SETTINGS : (path === allPaths?.VOUCHERS || path === allPaths?.INVOICES || path === allPaths?.PAYMENTS) ? allPaths?.FINANCIAL : (path === allPaths?.BUVOUCHERS || path === allPaths?.BUINVOICES || path === allPaths?.BUPAYMENTS || path === allPaths.SUBSCRIPTION) ? allPaths?.BUFINANCIAL : (path === allPaths?.ADMINVOUCHERS || path === allPaths?.ADMINPAYMENTS || path === allPaths?.ADMINBUINVOICES || path === allPaths?.ADMINGUESTINVOICES) ? allPaths.ADMIN_FINANCIAL : (path === allPaths?.PAYMENT_REPORT || path === allPaths?.COUPONS_REPORT || path === allPaths?.SUBSCRIPTION_REPORT || path === allPaths?.FEE_REPORT) ? allPaths.REPORT : 0


const successMessage = (desc = 'Successfully Complete!') => {
    return message.success(desc)
}

const infoMessage = (desc = 'Successfully Complete!') => {
    return message.info(desc)
}

const errorMessage = (desc = 'Oops Something Went Wrong!') => {
    return message.error(desc)
}
const errorMessageWithDuration = (desc = 'Oops Something Went Wrong!') => {
    return message.error(desc, [0.8])
}

const warningMessage = (desc = 'Warning!') => {
    return message.warning(desc)
}

const successNotification = (message = 'Successfully Complete!') => {
    return notification.success({ message })
}

const errorNotification = (message = 'Oops Something Went Wrong!') => {
    return notification.error({ message })
}

const userObject = (result) => {
    const { profileObj } = result
    return {
        email: profileObj.email,
        fullName: `${profileObj.givenName} ${profileObj.familyName}`,
        uid: profileObj.googleId
    }
}

const facebookLogin = (result, history, loginUser) => {
    const obj = {
        email: result?.email,
        uid: result.id,
        fullName: result.name,
    }
    axios.post(AUTH.SOCIAL_LOGIN, obj)
        .then((res) => {
            const { data } = res
            if (data.success) {
                loginUser(data.user)
                successMessage('Successfully Logged In!')
                return setTimeout(() => {
                    history.push('/')
                }, 300)
            }
            errorMessage(data.message)
        })
        .catch((e) => {
            errorMessage()
        })
}

const googleLogin = (result, history, loginUser) => {
    const obj = userObject(result)
    axios.post(AUTH.SOCIAL_LOGIN, obj)
        .then((res) => {
            const { data } = res
            if (data.success) {
                loginUser(data.user)
                successMessage('Successfully Logged In!')
                return setTimeout(() => {
                    history.push('/')
                }, 300)
            }
            errorMessage(data.message)
        })
        .catch((e) => {
            errorMessage()
        })
}

const convertTitle = (val) => val.charAt(0).toUpperCase() + val.slice(1)

const convertDate = (m) => momentTz(m)?.format('DD-MM-YYYY')

const convertNumber = (num) => `${parseFloat(num)?.toFixed(2)}`
const convertRoundNumber = (num) => `${(Math.round(num * 100) / 100).toFixed(2)}`
const convertNumberCardCoupon = (num) => {
    return `${parseFloat(num).toFixed(2)}`
}
const convertFloat = (num) => parseFloat(num)?.toFixed(2)

const dateFormater = (d) => momentTz(d).format('ddd MMM DD YYYY')

const splitDate = (date) => date?.split('T')[0]

const convertMessage = (msg, guest, user) => {
    let message = draftToHtml(msg)

    if (guest && user) {
        message = message?.replace(/@guestName/gi, guest?.name)
        message = message?.replace(/@name/gi, user?.fullName)
        message = message?.replace(/@guestEmail/gi, guest?.email)
        message = message?.replace(/@email/gi, user?.email)
        message = message?.replace(/@businessName/gi, guest?.businessName)
    }

    return ReactHtmlParser(message) || 'Not Found'
}

const createInvoicePdf = async (invoice, user, action, viewInvoice) => {
    // console.log('create Invoice Pdf function is working')
    console.log('invoice-=-=-=->', invoice)
    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    let iconUrl = 'https://res.cloudinary.com/dklfq58uq/image/upload/v1641853789/invoice-icon_czczkc.jpg'

    let imageBase64 = await toBase64(iconUrl)

    doc.image(imageBase64, 410, 10, { width: 150, height: 130 })
    if (user?.businessName) {
        doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, 60, 100)
    }
    doc.text(user?.fullName, 60, 120)
    doc.text(user?.streetNumber, 60, 140)
    doc.text(user?.postalCodeCity, 60, 160)
    doc.text(user?.country, 60, 180)
    if (invoice?.invoiceName === "Refund") {
        doc.fontSize(14).text(`Creditnota: ${invoice?.invoiceNumber}`, 60, 220)
    } else {
        doc.fontSize(14).text(`Invoice Number: ${invoice?.invoiceNumber}`, 60, 220)
    }

    // doc.text(`${user?.postalCodeCity}, ${dateFormater(invoice?.created)}`, 60, 240)
    doc.text(`Voorschoten, ${dateFormater(invoice?.created)}`, 60, 240)

    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 250)

    let x = 280
    let leftVal = 360
    let rightVal = 515
    let totalPrice = convertRoundNumber(invoice?.quantity * invoice?.price)
    let taxPercentage

    if (invoice?.shiftsId) {
        taxPercentage = invoice?.product?.tax

        if (invoice?.age) {
            let ages = Object.keys(invoice?.ageQuantity)
            ages.forEach(age => {
                let newTotalPrice = invoice?.ageQuantity?.[age] * invoice?.agePrice[0]?.[age]
                newTotalPrice = convertNumber(newTotalPrice)

                doc.text(`${invoice?.product?.name} (${age}) years`, 60, x)
                doc.text(`€ ${convertNumber(invoice?.agePrice[0]?.[age])} / piece`, 240, x)

                doc.text(`${invoice?.ageQuantity?.[age]} ${invoice?.product?.name} x € ${convertNumber(invoice?.agePrice[0]?.[age])}`, leftVal, x)

                doc.text(`€ ${newTotalPrice}`, rightVal - (newTotalPrice?.length * 5), x)
                x = x + 20
            })

        } else {

            doc.text(`${invoice?.product?.name}`, 60, x)

            doc.text(`€ ${convertNumber(invoice?.price)} / piece`, 240, x)

            doc.text(`${invoice?.quantity} ${invoice?.product?.name} x € ${convertNumber(invoice?.price)}`, leftVal, x)

            doc.text(`€ ${totalPrice}`, rightVal - (totalPrice?.length * 5), x)
            x = x + 20
        }


        if (invoice?.couponId?._id) {
            let couponAmount = convertNumber(invoice?.couponId?.amount)
            doc.text(`Coupon`, 60, x)

            doc.text(`€ ${convertNumber(invoice?.couponId?.amount)} / piece`, 240, x)

            doc.text(`1 Coupon x € ${couponAmount}`, leftVal, x)

            doc.text(`€ ${couponAmount}`, rightVal - (couponAmount?.length * 5), x)
            x = x + 20
        }

    } else if (invoice?.invoiceName === "Coupon") {
        taxPercentage = 21
        doc.text(`Coupon`, 60, x)

        doc.text(`€ ${convertNumber(invoice?.price)} / piece`, 240, x)

        doc.text(`${invoice?.quantity} Coupon x € ${convertRoundNumber(invoice?.price)}`, leftVal, x)

        doc.text(`€ ${totalPrice}`, rightVal - (totalPrice?.length * 5), x)
        x = x + 20
    } else if (invoice?.lotteryCardQuantity) {
        taxPercentage = 21
        doc.text(`${invoice?.invoiceName}`, 60, x)

        doc.text(`${invoice?.quantity} Lottery x € ${convertRoundNumber(invoice?.price)}`, leftVal, x)

        doc.text(`€ ${convertNumber(invoice?.price)} / piece`, 240, x)

        doc.text(`€ ${totalPrice}`, rightVal - (totalPrice?.length * 5), x)
        x = x + 20
    } else if (invoice?.giftCardsQuantity) {
        taxPercentage = 21
        doc.text(`${invoice?.invoiceName}`, 60, x)

        doc.text(`${invoice?.quantity} GiftCard x € ${convertRoundNumber(invoice?.price)}`, leftVal, x)

        doc.text(`€ ${convertNumber(invoice?.price)} / piece`, 240, x)

        doc.text(`€ ${totalPrice}`, rightVal - (totalPrice?.length * 5), x)
        x = x + 20

        if (invoice?.giftCardsPrice) {
            totalPrice = convertNumber(invoice?.giftCardsPrice * invoice?.quantity)
            doc.text(`Gift Cards value`, 60, x)

            doc.text(`${invoice?.quantity} GiftCard x € ${convertRoundNumber(invoice?.giftCardsPrice)}`, leftVal, x)

            doc.text(`€ ${convertNumber(invoice?.giftCardsPrice)} / piece`, 240, x)

            doc.text(`€ ${totalPrice}`, rightVal - (totalPrice?.length * 5), x)
            x = x + 20
        }

    } else if (invoice?.invoiceName === 'Gift Card Pay') {
        let totalAmount = convertRoundNumber(invoice?.totalAmount - invoice?.totalProvision)
        doc.text(`${invoice?.invoiceName}`, 60, x)

        doc.text(`€ ${totalAmount}`, rightVal - (totalAmount?.length * 5), x)
        x = x + 20
    } else if (invoice?.subscriptionId?._id) {
        taxPercentage = 21
        let price = convertRoundNumber(invoice?.price)

        doc.text(`${invoice?.invoiceName} ( ${invoice?.subscriptionId?.monthDuration} Months )`, 60, x)

        doc.text(`€ ${price}`, rightVal - (price?.length * 5), x)
        x = x + 20
    } else /* if (invoice?.subscriptionTypes.includes('shopOwner'))  */ {
        taxPercentage = 21
        let price = convertRoundNumber(invoice?.price)

        doc.text(`${invoice?.invoiceName}`, 60, x)

        doc.text(`€ ${price}`, rightVal - (price?.length * 5), x)
        x = x + 20
    }

    x = x + 80

    doc.text('Subtotal', leftVal, x)


    let SubTotaal = convertRoundNumber(invoice?.totalAmount * (100 / (100 + taxPercentage)))
    let provision = invoice?.totalProvision

    let total = convertRoundNumber(invoice?.totalAmount)

    if (provision) {
        provision = convertRoundNumber(invoice?.totalProvision)

        SubTotaal = convertRoundNumber(invoice?.totalAmount - invoice?.totalProvision)
        doc.text(`€ ${SubTotaal}`, rightVal - (SubTotaal?.length * 5), x)

        x = x + 20
        doc.text('7 % provision', leftVal, x)

        doc.text(`€ ${provision}`, rightVal - (provision?.length * 5), x)

        x = x + 20

    } else if (invoice?.giftCardsPrice) {
        let rateAmount = invoice?.giftCardsRate * invoice?.quantity
        SubTotaal = convertRoundNumber(rateAmount - (rateAmount * (21 / 121)))
        doc.text(`€ ${SubTotaal}`, rightVal - (SubTotaal?.length * 5), x)

        x = x + 20

        doc.text('21 % BTW', leftVal, x)

        let btw = convertNumber(rateAmount * (21 / 121))

        doc.text(`€ ${btw}`, rightVal - (btw?.length * 5), x)

        x = x + 20

        let cardsValue = convertNumber(invoice?.giftCardsQuantity * invoice?.giftCardsPrice)

        doc.text('Cards Value', leftVal, x)

        doc.text(`€ ${cardsValue}`, rightVal - (cardsValue?.length * 5), x)

        x = x + 20

        doc.text('Total incl BTW', leftVal, x)
    } else {
        doc.text(`€ ${SubTotaal}`, rightVal - (SubTotaal?.length * 5), x)

        x = x + 20
        doc.text(`${taxPercentage} % BTW`, leftVal, x)

        let btw = convertRoundNumber(total - SubTotaal)

        doc.text(`€ ${btw}`, rightVal - (btw?.length * 5), x)

        x = x + 20
    }
    if (invoice?.invoiceName === "Refund") {
        doc.text('Total incl BTW                -/-', leftVal, x)
    } else {
        doc.text('Total incl BTW', leftVal, x)
    }

    doc.text(`€ ${total}`, rightVal - (total?.length * 5), x)


    x = x + 40

    // doc.text(`Wij zien uw betaling graag binnen 5 dagen tegemoet tnv 2keepintouch.com`, 60, x)

    x = x + 20

    doc.text('______________________________________________________________________________________', 60, x)

    x = x + 20

    doc.font('Helvetica-Bold').text('2keepintouch.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    x = x + 20

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        if (action === 'view') {
            viewInvoice(blobUrl)
        } else {
            a.href = blobUrl
            a.download = 'invoice.pdf'
            a.click()
        }
    })
}

const createBuInvoicePdf = async (invoice, user, action, viewInvoice) => {
    // console.log('create Invoice Pdf function is working')
    console.log('invoice-=-=->', invoice)
    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    let iconUrl = 'https://res.cloudinary.com/dklfq58uq/image/upload/v1641853789/invoice-icon_czczkc.jpg'

    let imageBase64 = await toBase64(iconUrl)
    let date1 = moment(invoice?.date1).format('YYYY-MM-DD')
    let date2 = moment(invoice?.date2).format('YYYY-MM-DD')

    doc.image(imageBase64, 410, 10, { width: 150, height: 130 })
    if (user?.businessName) {
        doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, 60, 100)
    }
    doc.text(user?.fullName, 60, 120)
    doc.text(user?.streetNumber, 60, 140)
    doc.text(user?.postalCodeCity, 60, 160)
    doc.text(user?.country, 60, 180)

    doc.fontSize(14).text(`Invoice Number: ${invoice?.invoiceNumber}`, 60, 220)
    doc.fontSize(14).text(`Period: ${dateFormater(date2)}-${dateFormater(date1)}`, 60, 240)

    doc.text(`Voorschoten, ${dateFormater(invoice?.created)}`, 60, 260)

    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 270)

    let x = 290
    let leftVal = 360
    let rightVal = 515

    for (let invoiceData of invoice?.invoiceData) {
        if (invoiceData?.age) {

            let products = convertRoundNumber(invoiceData?.quantity * invoiceData?.feeCost)
            doc.text(`${invoiceData?.productName}`, 60, x)

            doc.text(`${invoiceData?.quantity}`, 180, x)

            doc.text(`€ ${convertRoundNumber(invoiceData?.feeCost)}`, 250, x)

            doc.text(`${invoiceData?.quantity} ${invoiceData?.productName} x € ${convertRoundNumber(invoiceData?.feeCost)}`, leftVal, x)

            doc.text(`€ ${products}`, rightVal - (products?.length * 5), x)
            x = x + 20
        } else {

            let products = convertRoundNumber(invoiceData?.quantity * invoiceData?.feeCost)
            doc.text(`${invoiceData?.productName}`, 60, x)

            doc.text(`${invoiceData?.quantity}`, 180, x)

            doc.text(`€ ${convertRoundNumber(invoiceData?.feeCost)}`, 250, x)

            doc.text(`${invoiceData?.quantity} ${invoiceData?.productName} x € ${convertRoundNumber(invoiceData?.feeCost)}`, leftVal, x)

            doc.text(`€ ${products}`, rightVal - (products?.length * 5), x)
            x = x + 20

        }
    }

    x = x + 80

    doc.text('Subtotal', leftVal, x)

    let taxPercentage = 21
    let SubTotaal = (invoice?.invoiceData?.reduce((sum, { quantity }) => sum + quantity, 0) * invoice?.invoiceData[0]?.feeCost)
    let taxedAmount = SubTotaal * (taxPercentage / 100)
    let totalAmount = SubTotaal + taxedAmount
    SubTotaal = convertRoundNumber(SubTotaal)

    let total = convertRoundNumber(totalAmount)
    // let total = totalAmount?.toString()
    doc.text(`€ ${SubTotaal}`, rightVal - (SubTotaal?.length * 5), x)

    x = x + 20

    doc.text(`${taxPercentage} % BTW`, leftVal, x)

    let btw = convertRoundNumber(taxedAmount)

    doc.text(`€ ${btw}`, rightVal - (btw?.length * 5), x)

    x = x + 20

    doc.text('Total incl BTW', leftVal, x)

    doc.text(`€ ${total}`, rightVal - (total?.length * 5), x)


    x = x + 40

    // doc.text(`Wij zien uw betaling graag binnen 5 dagen tegemoet tnv 2keepintouch.com`, 60, x)

    x = x + 20

    doc.text('______________________________________________________________________________________', 60, x)

    x = x + 20

    doc.font('Helvetica-Bold').text('2keepintouch.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    x = x + 20

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        if (action === 'view') {
            viewInvoice(blobUrl)
        } else {
            a.href = blobUrl
            a.download = 'invoice.pdf'
            a.click()
        }
    })
}

const toBase64 = async (url) => {
    try {
        const data = await fetch(url)
        const blob = await data.blob()
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                const base64data = reader.result
                resolve(base64data)
            }
        })
    } catch (e) {
        console.log('e', e)
    }
}

const createLoyaltyCard = async (arr, loyaltyAdvertisment) => {
    try {
        const doc = new PDFDocument({ size: [907.1, 1275.6] })
        const stream = doc.pipe(blobStream())

        let width = 209.76
        let height = 147.4

        var x = 13
        var y = 10

        let iconUrl = loyaltyAdvertisment?.imageUrl || 'https://res.cloudinary.com/dklfq58uq/image/upload/v1641853789/invoice-icon_czczkc.jpg'
        console.log('iconUrl', iconUrl)
        let imageBase64 = await toBase64(iconUrl)

        console.log('imageBase64', imageBase64)

        for (var i in arr) {
            let obj = arr[i]

            doc.image(imageBase64, x, y, { width, height })

            var index = Number(i) + 1

            if (index % 32 === 0) {
                x = 13
                y = 10
                doc.addPage()
            }
            else if (index % 4 === 0) {
                x = 13
                y += height + 10
            }
            else {
                x += width + 14
            }

        }

        doc.addPage()

        var xAxis = 0
        var yAxis = 0
        let QRwidth = 180
        let QRheight = 100

        for (var i in arr) {
            let obj = arr[i]

            doc.moveTo(20 + xAxis, 20 + yAxis).lineTo(215 + xAxis, 20 + yAxis).stroke()
            doc.moveTo(20 + xAxis, 20 + yAxis).lineTo(20 + xAxis, 153 + yAxis).stroke()
            doc.moveTo(20 + xAxis, 153 + yAxis).lineTo(215 + xAxis, 153 + yAxis).stroke()
            doc.moveTo(215 + xAxis, 20 + yAxis).lineTo(215 + xAxis, 153 + yAxis).stroke()
            let qrImage = await generateQR(obj?.couponCode)

            let qrX = 60 + xAxis
            let qrY = 21 + yAxis

            doc.image(qrImage, qrX, qrY, { QRwidth, QRheight })

            y = 100 + yAxis
            x = 80 + xAxis
            let lineX = 78 + xAxis
            let lineY = 114 + yAxis
            var lineXEnd = 156 + xAxis

            lineY = lineY + 35
            y = y + 35

            doc.text(obj?.couponCode, x, y)
            doc.moveTo(lineX, lineY).lineTo(lineXEnd, lineY).stroke()

            var index = Number(i) + 1

            if (index % 32 === 0) {
                xAxis = 0
                yAxis = 0
                doc.addPage()
            }
            else if (index % 4 === 0) {
                xAxis = 0
                yAxis += height + 10
            }
            else {
                xAxis += width + 14
            }

        }

        doc.end()
        stream.on('finish', () => {
            const blob = stream.toBlob('application/pdf')
            const blobUrl = URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.href = blobUrl
            a.download = 'loyalty.pdf'
            a.click()
        })

    } catch (e) {
        errorMessage(e)
    }
}

const createGiftCard = async (arr, spinFunc) => {
    try {
        const doc = new PDFDocument({ size: [907.1, 1275.6] })
        const stream = doc.pipe(blobStream())

        let width = 209.76
        let height = 147.4

        var x = 13
        var y = 10
        console.log('a')
        let iconUrl = arr[0].imageUrl || 'https://res.cloudinary.com/dklfq58uq/image/upload/v1641853789/invoice-icon_czczkc.jpg'
        let imageBase64 = await toBase64(iconUrl)
        for (var i in arr) {
            console.log('e')

            doc.image(imageBase64, x, y, { width, height })

            var index = Number(i) + 1

            if (index % 32 === 0) {
                x = 13
                y = 10
                doc.addPage()
            }
            else if (index % 4 === 0) {
                x = 13
                y += height + 10
            }
            else {
                x += width + 14
            }
        }
        console.log('b')
        doc.addPage()

        var xAxis = 0
        var yAxis = 0
        let QRwidth = 180
        let QRheight = 100


        for (var i in arr) {
            console.log('c')

            let obj = arr[i]
            console.log('obj', obj)
            let code = obj?.couponCode || obj?.giftCardCode
            doc.moveTo(20 + xAxis, 20 + yAxis).lineTo(215 + xAxis, 20 + yAxis).stroke()
            doc.moveTo(20 + xAxis, 20 + yAxis).lineTo(20 + xAxis, 153 + yAxis).stroke()
            doc.moveTo(20 + xAxis, 153 + yAxis).lineTo(215 + xAxis, 153 + yAxis).stroke()
            doc.moveTo(215 + xAxis, 20 + yAxis).lineTo(215 + xAxis, 153 + yAxis).stroke()

            let qrImage = await generateQR(code)

            let qrX = 60 + xAxis
            let qrY = 21 + yAxis

            doc.image(qrImage, qrX, qrY, { QRwidth, QRheight })

            y = 100 + yAxis
            x = 80 + xAxis
            let lineX = 78 + xAxis
            let lineY = 114 + yAxis
            var lineXEnd = 156 + xAxis

            lineY = lineY + 35
            y = y + 35

            // doc.text('abcd', x, y-5)
            // 
            doc.text(code, x, y)
            doc.moveTo(lineX, lineY).lineTo(lineXEnd, lineY).stroke()

            var index = Number(i) + 1

            if (index % 32 === 0) {
                xAxis = 0
                yAxis = 0
                doc.addPage()
            }
            else if (index % 4 === 0) {
                xAxis = 0
                yAxis += height + 10
            }
            else {
                xAxis += width + 14
            }
        }
        console.log('d')

        doc.end()
        stream.on('finish', () => {
            const blob = stream.toBlob('application/pdf')
            const blobUrl = URL.createObjectURL(blob)
            const a = document.createElement('a')

            a.href = blobUrl
            a.download = 'giftCard.pdf'
            spinFunc()
            a.click()
        })
    } catch (e) {
        console.log('error', e)
        spinFunc()
        return errorMessage()
    }

}

const createLotteryCard = async (arr, loyaltyAdvertisment) => {
    const doc = new PDFDocument({ size: [907.1, 1275.6] })
    const stream = doc.pipe(blobStream())

    let width = 209.76
    let height = 147.4
    let QRwidth = 180
    let QRheight = 100

    var x = 13
    var y = 10

    let iconUrl = loyaltyAdvertisment?.imageUrl || 'https://res.cloudinary.com/dklfq58uq/image/upload/v1641853789/invoice-icon_czczkc.jpg'

    let imageBase64 = await toBase64(iconUrl)

    for (var i in arr) {

        doc.image(imageBase64, x, y, { width, height })

        var index = Number(i) + 1

        if (index % 32 === 0) {
            x = 13
            y = 10
            doc.addPage()
        }
        else if (index % 4 === 0) {
            x = 13
            y += height + 10
        }
        else {
            x += width + 14
        }
    }

    doc.addPage()

    var xAxis = 0
    var yAxis = 0

    for (var i in arr) {
        let obj = arr[i]

        doc.moveTo(20 + xAxis, 20 + yAxis).lineTo(215 + xAxis, 20 + yAxis).stroke()
        doc.moveTo(20 + xAxis, 20 + yAxis).lineTo(20 + xAxis, 153 + yAxis).stroke()
        doc.moveTo(20 + xAxis, 153 + yAxis).lineTo(215 + xAxis, 153 + yAxis).stroke()
        doc.moveTo(215 + xAxis, 20 + yAxis).lineTo(215 + xAxis, 153 + yAxis).stroke()

        let qrImage = await generateQR(obj?.lotteryCode)

        let qrX = 60 + xAxis
        let qrY = 21 + yAxis

        doc.image(qrImage, qrX, qrY, { QRwidth, QRheight })

        y = 100 + yAxis
        x = 80 + xAxis
        let lineX = 78 + xAxis
        let lineY = 114 + yAxis
        var lineXEnd = 156 + xAxis

        lineY = lineY + 35
        y = y + 35
        doc.text(obj?.lotteryCode, x, y)
        doc.moveTo(lineX, lineY).lineTo(lineXEnd, lineY).stroke()

        var index = Number(i) + 1

        if (index % 32 === 0) {
            xAxis = 0
            yAxis = 0
            doc.addPage()
        }
        else if (index % 4 === 0) {
            xAxis = 0
            yAxis += height + 10
        }
        else {
            xAxis += width + 14
        }
    }

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'lotteryCard.pdf'

        a.click()
    })
}

const createPostalCard = async (arr) => {
    const doc = new PDFDocument({ size: [907.1, 1275.6] })
    const stream = doc.pipe(blobStream())

    let width = 405.3
    let height = 283.4

    var x = 0
    var y = 0

    let imageUrl = arr[0]?.card1?.imageUrl || arr[0]?.card2?.imageUrl || arr[0]?.card3?.imageUrl || arr[0]?.card4?.imageUrl || arr[0]?.card5?.imageUrl
    let imageBase64 = await toBase64(imageUrl)

    for (var i in arr) {

        doc.image(imageBase64, x, y, { width, height })

        if (Number(i) % 2 === 1) {
            x = 0
            y += height + 20
        }
        else {
            x += width + 35
        }
    }

    doc.addPage()

    var xAxis = 0
    var yAxis = 0

    for (var i in arr) {
        let obj = arr[i]
        let guest = obj?.guest

        let message = draftToHtml(obj?.message1 || obj?.message2 || obj?.message3 || obj?.message4 || obj?.message5)

        message = message?.replace(/@guestName/gi, guest?.name)
        message = message?.replace(/@name/gi, obj?.userId?.fullName)
        message = message?.replace(/@guestEmail/gi, guest?.email)
        message = message?.replace(/@email/gi, obj?.userId?.email)
        message = message?.replace(/@businessName/gi, guest?.businessName)
        message = message?.replace(/&nbsp;/gi, ' ')

        let plainText = message?.replace(/<[^>]+>/g, '')

        doc.lineJoin('miter')
            .rect(380 + xAxis, 40 + yAxis, 45, 60)
            .stroke()

        let messageArray = chunk(plainText, 32)
        let x = 237 + xAxis
        let y = 70 + yAxis
        let lineX = 237 + xAxis
        let lineY = 125 + yAxis
        var text

        for (var v of messageArray) {
            text = v?.join('')
            doc.text(`${text}`, 40 + xAxis, y)
            y = y + 20
        }

        let lineXStart = 35

        doc.moveTo(lineXStart + xAxis, lineXStart + yAxis).lineTo(430 + xAxis, lineXStart + yAxis).stroke()
        doc.moveTo(lineXStart + xAxis, lineXStart + yAxis).lineTo(lineXStart + xAxis, 298 + yAxis).stroke()
        doc.moveTo(lineXStart + xAxis, 298 + yAxis).lineTo(430 + xAxis, 298 + yAxis).stroke()
        doc.moveTo(430 + xAxis, lineXStart + yAxis).lineTo(430 + xAxis, 298 + yAxis).stroke()

        doc.moveTo(232.5 + xAxis, 45 + yAxis).lineTo(232.5 + xAxis, 290 + yAxis).stroke()

        y = 110 + yAxis
        var lineXEnd = 420 + xAxis

        if (guest?.businessName) {
            doc.text(guest?.businessName, x, y)
            doc.moveTo(lineX, lineY).lineTo(lineXEnd, lineY).stroke()
        }

        lineY = lineY + 35
        y = y + 35

        doc.text(guest?.name, x, y)
        doc.moveTo(lineX, lineY).lineTo(lineXEnd, lineY).stroke()

        lineY = lineY + 35
        y = y + 35

        doc.text(guest?.streetHouse, x, y)
        doc.moveTo(lineX, lineY).lineTo(lineXEnd, lineY).stroke()

        lineY = lineY + 35
        y = y + 35

        doc.text(guest?.postalCodeCity, x, y)
        doc.moveTo(lineX, lineY).lineTo(lineXEnd, lineY).stroke()

        lineY = lineY + 35
        y = y + 35

        doc.text(guest?.country, x, y)

        if (Number(i) % 2 === 1) {
            xAxis = 0
            yAxis += height + 20
        }
        else {
            xAxis += width + 35
        }
    }

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'postalCard.pdf'

        a.click()
    })
}

const createFrontSide = async (obj) => {
    const doc = new PDFDocument({
        size: [405.3, 283.4],
    })

    let imageUrl = obj?.card1?.imageUrl || obj?.card2?.imageUrl || obj?.card3?.imageUrl || obj?.card4?.imageUrl || obj?.card5?.imageUrl

    let imageBase64 = await toBase64(imageUrl)

    const stream = doc.pipe(blobStream())

    doc.image(imageBase64, 0, 0, { width: 405.3, height: 283.4 })

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'file.pdf'

        a.click()
    })
}

const createBackSide = async (obj) => {
    const doc = new PDFDocument({ size: [405.3, 283.4] })

    let guest = obj?.guest

    let message = draftToHtml(obj?.message1 || obj?.message2 || obj?.message3 || obj?.message4 || obj?.message5)

    message = message?.replace(/@guestName/gi, guest?.name)
    message = message?.replace(/@name/gi, obj?.userId?.fullName)
    message = message?.replace(/@guestEmail/gi, guest?.email)
    message = message?.replace(/@email/gi, obj?.userId?.email)
    message = message?.replace(/@businessName/gi, guest?.businessName)
    message = message?.replace(/&nbsp;/gi, ' ')

    let plainText = message?.replace(/<[^>]+>/g, '')

    const stream = doc.pipe(blobStream())

    doc.lineJoin('miter')
        .rect(350, 10, 45, 60)
        .stroke()

    let messageArray = chunk(plainText, 32)
    let y = 40
    let x = 207
    let lineX = 207
    let lineY = 105
    var text

    for (var v of messageArray) {
        text = v?.join('')
        // lastChar = text?.slice(-1,)[0]

        // if (y === 40) {
        //     if (lastChar.charCodeAt(0) >= 65 && lastChar.charCodeAt(0) <= 90 || lastChar.charCodeAt(0) >= 97 && lastChar.charCodeAt(0) <= 122) {
        //         doc.text(`${text}-`, 10, y)
        //     }
        //     else {
        //         doc.text(`${text}`, 10, y)
        //     }
        // }
        // else {
        //     doc.text(`${text}`, 10, y)
        // }
        doc.text(`${text}`, 10, y)
        y = y + 20
    }

    doc.moveTo(5, 5).lineTo(400, 5).stroke()
    doc.moveTo(5, 5).lineTo(5, 278).stroke()
    doc.moveTo(5, 278).lineTo(400, 278).stroke()
    doc.moveTo(400, 5).lineTo(400, 278).stroke()

    doc.moveTo(202.5, 15).lineTo(202.5, 260).stroke()

    y = 90

    if (guest?.businessName) {
        doc.text(guest?.businessName, x, y)
        doc.moveTo(lineX, lineY).lineTo(390, lineY).stroke()
    }

    lineY = lineY + 35
    y = y + 35

    doc.text(guest?.name, x, y)
    doc.moveTo(lineX, lineY).lineTo(390, lineY).stroke()

    lineY = lineY + 35
    y = y + 35

    doc.text(guest?.streetHouse, x, y)
    doc.moveTo(lineX, lineY).lineTo(390, lineY).stroke()

    lineY = lineY + 35
    y = y + 35

    doc.text(guest?.postalCodeCity, x, y)
    doc.moveTo(lineX, lineY).lineTo(390, lineY).stroke()

    lineY = lineY + 35
    y = y + 35

    doc.text(guest?.country, x, y)
    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'file.pdf'

        a.click()
    })
}

const setHoursMinutes = (val) => momentTz(val).set('hour', 0).set('minute', 0).set('second', 0)

const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
}

const disabledDate = (current) => {
    let customDate = momentTz().format('YYYY-MM-DD')
    return current && current < momentTz(customDate, 'YYYY-MM-DD')
}

const absoluteDate = (date, hour) => {
    let d = absoluteLocalDate(date, hour)

    return momentTz.utc(d)
}

const absoluteLocalDate = (date, hour) => {
    let d = momentTz(date)

    d.set('hours', 0)
    d.set('seconds', 0)

    d.set('minutes', hour || 0)

    return d
}

const relativeDate = (date, hour) => {
    let d = momentTz().add(date, 'days')

    d.set('hours', 0)
    d.set('seconds', 0)

    d.set('minutes', hour || 0)

    return momentTz.utc(d)
}

const absoluteDateTz = (date, hour) => {
    let d = absoluteLocalDateTz(date, hour)

    return momentTz.utc(d)
}

const absoluteLocalDateTz = (date, hour) => {
    let d = momentTz(date)

    d.set('hours', 0)
    d.set('seconds', 0)

    d.set('minutes', hour || 0)

    return d
}

const relativeDateTz = (date, hour) => {
    let d = momentTz().add(date, 'days')

    d.set('hours', 0)
    d.set('seconds', 0)

    d.set('minutes', hour || 0)

    return momentTz.utc(d)
}

const stringLimiter = (val, limit = 50) => val?.length > limit ? `${val.slice(0, limit)}...` : val

const generateQR = async (text) => {
    let result = await QRCode.toDataURL(text)

    return result
}

const provisionCalculate = (amount) => {
    let provisionPercentage = amount * 0.07
    if (provisionPercentage > 0.5 && provisionPercentage < 2) {
        return convertNumber(provisionPercentage)
    } else if (provisionPercentage < 0.5) {
        return 0.5
    } else {
        return 2
    }
}

const validateTime = (t) => {
    if (t != 'none' && t?.length === 4) {
        return `${t}0`;
    }
    return t;
}

const fetchData = async (ROUTE, action) => {
    axios.get(ROUTE)
        .then((res) => {
            const { data } = res
            action(data?.data || [])
        })
}


const fetchDataParams = async (ROUTE, action, userId, obj = false) => {
    axios.get(`${ROUTE}/${userId}`)
        .then((res) => {
            const { data } = res
            if (data?.success) {
                if (obj) {
                    action(data?.data || [])
                } else {
                    action(data?.data || {})
                }
            }
        })
}
const fetchDataParamsForInvoice = async (ROUTE, action, userId,) => {
    axios.get(`${ROUTE}/${userId}`)
        .then((res) => {
            const { data } = res
            if (data?.success) {
                action(data || {})
            }
        })
}

const allAdminData = {
    getGuestUser: (adminActions, userId) => fetchDataParams(GET.ALL_GUEST_USERS, adminActions?.addAllGuestUsers, userId),
    getBusinessUser: (adminActions, userId) => fetchDataParams(GET.ALL_BUSINESS_USERS, adminActions?.addAllBusinessUsers, userId),
    getSubAdmins: (adminActions, userId) => fetchDataParams(ADMIN.GETALLSUBADMINS, adminActions?.addAllSubAdmins, userId),
    getAllGroups: (adminActions, userId) => fetchDataParams(ADMIN.GET_GROUPS, adminActions?.addAllGroups, userId),
    getAdminVoucher: (adminActions, userId) => fetchDataParams(ADMIN.GET_ADMIN_VOUCHER, adminActions?.addAllVouchers, userId),
    getAdminPayment: (adminActions, userId) => fetchDataParams(ADMIN.GET_ADMIN_PAYMENTS, adminActions?.addAllPayments, userId),
    getAdminRateList: (adminActions, userId) => fetchDataParams(ADMIN.GET_RATE_LIST, adminActions?.setRates, userId),
    all: function (adminActions, userId) {
        this.getGuestUser(adminActions, userId)
        this.getBusinessUser(adminActions, userId)
        this.getSubAdmins(adminActions, userId)
        this.getAllGroups(adminActions, userId)
        this.getAdminVoucher(adminActions, userId)
        this.getAdminPayment(adminActions, userId)
        this.getAdminRateList(adminActions, userId)
    }
}

const generalData = {
    getAllCities: (generalActions) => fetchData(GET?.GET_CITIES, generalActions?.setCities),
    getCountryTax: (generalActions) => fetchData(GET?.GET_COUNTRY_TAX, generalActions?.setTaxes),
    all: function (generalActions) {
        this.getAllCities(generalActions)
        this.getCountryTax(generalActions)
    }
}

const allUserData = {
    getAllInvoice: (userActions, userId) => fetchDataParams(GET.INVOICES, userActions?.addAllInvoices, userId),
    getAllVoucher: (userActions, userId) => fetchDataParams(GET.GET_GUEST_VOUCHERS, userActions?.addAllVouchers, userId),
    getActiveSubscription: (userActions, userId) => fetchDataParams(GET.ACTIVE_SUBSCRIPTION, userActions?.addAllSubscriptions, userId),
    getRateList: (userActions, userId) => fetchDataParams(GET.GET_RATE_LIST, userActions?.setRates, userId),
    getJoinedGroup: (userActions, userId) => fetchDataParams(GET.USER_JOINED_GROUPS, userActions?.addUserJoinedGroups, userId),
    getPendingGroups: (userActions, userId) => fetchDataParams(GET.USER_PENDING_GROUPS, userActions?.addUserPendingGroups, userId),
    all: function (userActions, userId) {
        this.getAllInvoice(userActions, userId)
        this.getAllVoucher(userActions, userId)
        this.getActiveSubscription(userActions, userId)
        this.getRateList(userActions, userId)
        this.getJoinedGroup(userActions, userId)
        this.getPendingGroups(userActions, userId)
    }
}

const allBUserData = {
    getAllBInvoice: (userActions, userId) => fetchDataParamsForInvoice(GET.GET_BU_INVOICES, userActions?.addAllBuInvoices, userId),
    getAllVoucher: (userActions, userId) => fetchDataParams(GET.GET_BU_VOUCHERS, userActions?.addAllVouchers, userId),
    getAllBuPayment: (userActions, userId) => fetchDataParams(GET.GET_BU_PAYMENTS, userActions?.addAllPayments, userId),
    getAllBuProducts: (userActions, userId) => fetchDataParams(GET.GET_BU_PRODUCTS, userActions?.addAllBuProducts, userId),
    all: function (userActions, userId) {
        this.getAllBInvoice(userActions, userId)
        this.getAllVoucher(userActions, userId)
        this.getAllBuPayment(userActions, userId)
        this.getAllBuProducts(userActions, userId)
    }
}


const getAllUserData = async (userId, userActions) => {

    axios.get(`${GET.INVOICES}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions?.addAllInvoices(data?.data || {})
        })

    axios.get(`${GET.GET_BU_INVOICES}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions?.addAllBuInvoices(data || {})
        })

    axios.get(`${GET.GET_GUEST_VOUCHERS}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions?.addAllVouchers(data?.data || [])
        })

    axios.get(`${GET.ACTIVE_SUBSCRIPTION}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions?.addAllSubscriptions(data?.data || [])
        })

    axios.get(`${GET.GET_RATE_LIST}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions?.setRates(data?.data || [])
        })

    axios.get(`${GET.USER_JOINED_GROUPS}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addUserJoinedGroups(data?.data || [])
        })

    axios.get(`${GET.USER_PENDING_GROUPS}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions.addUserPendingGroups(data?.data || [])
        })

}

const getBuUserData = async (userId, userActions) => {
    axios.get(`${GET.INVOICES}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions?.addAllInvoices(data?.data || {})
        })
    axios.get(`${GET.GET_BU_VOUCHERS}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions?.addAllVouchers(data?.data || [])
        })
    axios.get(`${GET.GET_BU_PAYMENTS}/${userId}`)
        .then((res) => {
            const { data } = res
            userActions?.addAllPayments(data?.data || [])
        })
}

// review Avrage
const calculateReviewAverage = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.average;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)

}

const calculateAverageForStaff = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.staff;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}
const calculateAverageForFacilities = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.facilities;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}
const calculateAverageForCleanliness = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.cleanliness;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}
const calculateAverageForComfort = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.comfort;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}
const calculateAverageForValueForMoney = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.valueForMoney;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}
const calculateAverageForLocation = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.location;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}
const calculateAverageForFreeWifi = (array) => {
    var total = 0
    var count = 0


    array?.forEach((item, index) => {
        total += item?.freeWifi;
        count++
    })
    let totalAvg = total / count

    return totalAvg === 10 ? 10 : totalAvg.toFixed(1)
}


const createPaymentReportPdf = async (invoice, user, action, viewInvoice) => {
    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    let iconUrl = 'https://res.cloudinary.com/dklfq58uq/image/upload/v1641853789/invoice-icon_czczkc.jpg'
    let startX = 40
    let imageBase64 = await toBase64(iconUrl)
    let date1 = moment(invoice?.date1).format('YYYY-MM-DD')
    let date2 = moment(invoice?.date2).format('YYYY-MM-DD')
    doc.image(imageBase64, 410, 10, { width: 150, height: 130 })
    if (user?.businessName) {
        doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, startX, 100)
    }
    doc.text(user?.fullName, startX, 120)
    doc.text(user?.streetNumber, startX, 140)
    doc.text(user?.postalCodeCity, startX, 160)
    doc.text(user?.country, startX, 180)

    doc.fontSize(14).text(`Payment Report: ${invoice?.reportNumber}`, startX, 220)
    doc.fontSize(14).text(`Period: ${dateFormater(date2)}-${dateFormater(date1)}`, startX, 240)

    doc.text(`Voorschoten, ${dateFormater(invoice?.created)}`, startX, 260)

    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________________', startX, 270)

    let x = 270
    let first = 90 - 20 - 20
    let second = 170 - 10 - 20 - 10
    let third = 250 - 10 - 20 - 10
    let fourth = 350 - 20 - 10
    let fifth = 450 - 20 - 30
    let sixth = 550 - 20 - 30
    let tax = 0.21
    let payments = invoice?.payments
    let products = _.uniq(payments?.map((v) => v?.product?._id))
    let totalAmounts = []
    let totalFeeCosts = []
    let digitValue = 6.5

    for (let product of products) {
        let filteredPayments = payments?.filter((v, i) => v?.product?._id === product)
        let total = filteredPayments?.reduce((sum, { invoice }) => sum + invoice?.totalAmount, 0)
        let quantity = filteredPayments?.reduce((sum, { invoice }) => sum + invoice?.quantity, 0)
        // let fee = filteredPayments[0]?.productType?.feeCost * quantity
        // let totalFee = fee
        let totalFeeQuantity = []
        let fee
        let totalFee
        totalAmounts?.push(total)
        // totalFeeCosts?.push(totalFee)
        if (x > 700) {
            doc.addPage()
            x = 30
        }

        let leftPrice = 150
        let rightPriceValue = 220


        x = x + 40
        doc.font('Helvetica-Bold').fontSize(12).text(`Product Name:`, startX, x)

        doc.font('Helvetica').fontSize(12).text(`${filteredPayments[0]?.product?.name}`, leftPrice, x)

        x = x + 20
        doc.font('Helvetica-Bold').fontSize(12).text(`Product Type:`, startX, x)

        doc.font('Helvetica').fontSize(12).text(`${filteredPayments[0]?.productType?.typeName}`, leftPrice, x)

        x = x + 20
        doc.font('Helvetica-Bold').fontSize(12).text(`Fee Cost:`, startX, x)

        doc.font('Helvetica').fontSize(12).text(`€`, leftPrice, x)

        let feeCostString = convertRoundNumber(filteredPayments[0]?.productType?.feeCost)
        // let feeCostString = '0:00'
        let priceString
        let isAge = filteredPayments[0]?.product?.agePrice

        priceString = convertRoundNumber(filteredPayments[0]?.product?.price[0]?.price)
        if (isAge) {
            let priceObj = Object.keys(filteredPayments[0]?.product?.price[0])
            let agePrice = filteredPayments[0]?.product?.price[0]
            priceString = '€' + ' (' + priceObj?.map((v) => agePrice?.[v]?.price)?.join('/ ') + ') '
        } else {
            priceString = priceString

        }


        doc.font('Helvetica').fontSize(12).text(`${feeCostString}`, rightPriceValue - (feeCostString?.length * digitValue), x)
        // doc.font('Helvetica').fontSize(12).text(`€    ${filteredPayments[0]?.productType?.feeCost}`, 140, x)

        x = x + 20
        doc.font('Helvetica-Bold').fontSize(12).text(`Price:`, startX, x)
        if (isAge) {
            doc.font('Helvetica').fontSize(12).text(`${priceString}`, leftPrice, x)
            // doc.font('Helvetica').fontSize(12).text(`${priceString}`, rightPriceValue - (priceString?.length * digitValue), x)
        } else {
            doc.font('Helvetica').fontSize(12).text(`€`, leftPrice, x)
            doc.font('Helvetica').fontSize(12).text(`${priceString}`, rightPriceValue - (priceString?.length * digitValue), x)
        }
        // doc.font('Helvetica').fontSize(12).text(`€    ${filteredPayments[0]?.product?.price}`, 140, x)

        x = x + 30

        doc.font('Helvetica-Bold').fontSize(11).text(`Res. Date`, first, x)
        doc.font('Helvetica-Bold').fontSize(11).text(`Time Slot`, second, x)
        doc.font('Helvetica-Bold').fontSize(11).text(`Guest Name`, third, x)
        doc.font('Helvetica-Bold').fontSize(11).text(`Quantity`, fourth, x)
        doc.font('Helvetica-Bold').fontSize(11).text(`Total Amount`, fifth, x)
        doc.font('Helvetica-Bold').fontSize(11).text(`Total Fee`, sixth, x)

        x = x + 30

        for (let detail of filteredPayments) {
            let totalAmount = convertRoundNumber(detail?.invoice?.totalAmount)
            // let totalAmount = '0'
            let quantity = detail?.invoice?.quantity
            let feeQuantity = detail?.invoice?.quantity

            if (detail?.invoice?.age) {
                feeQuantity = Object.keys(detail?.invoice?.ageQuantity)?.reduce((c, a) => detail?.invoice?.agePrice[0]?.[a] ? c + detail?.invoice?.ageQuantity?.[a] : c + 0, 0)
            }
            let freeQuantity = (quantity - feeQuantity)

            totalFeeQuantity.push(feeQuantity)
            let feeCost = +convertRoundNumber(detail?.productType?.feeCost * feeQuantity)
            let totalFeeCost = convertRoundNumber(feeCost)
            // let totalFeeCost = '0'
            let guestName = detail?.voucher?.userId?.fullName

            doc.font('Helvetica').fontSize(12).text(`${moment(detail?.voucher?.voucherDate)?.format('YYYY-MM-DD')}`, first, x)
            doc.font('Helvetica').fontSize(12).text(`${validateTime(detail?.voucher?.voucherTime)}`, second + 10, x)
            doc.font('Helvetica').fontSize(12).text(`${guestName}`, third, x)
            if (freeQuantity) {
                doc.font('Helvetica').fontSize(12).text(`${quantity} (${freeQuantity} free)`, fourth + 20 - ((`${quantity}`)?.length * digitValue), x)
            } else {
                doc.font('Helvetica').fontSize(12).text(`${quantity}`, fourth + 20 - ((`${quantity}`)?.length * digitValue), x)

            }
            doc.font('Helvetica').fontSize(12).text(`€`, fifth + 10, x)
            doc.font('Helvetica').fontSize(12).text(`${totalAmount}`, fifth + 70 - (totalAmount?.length * digitValue), x)
            doc.font('Helvetica').fontSize(12).text(`€`, sixth, x)
            doc.font('Helvetica').fontSize(12).text(`${totalFeeCost}`, sixth + 55 - (totalFeeCost?.length * digitValue), x)
            if (x >= 700) {
                doc.addPage()
                x = 30
            } else {
                x = x + 20
            }
        }
        doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________________', startX, x)


        x = x + 20

        // quantity = filteredPayments?.reduce((sum, { invoice }) => sum + invoice?.quantity, 0)
        fee = filteredPayments[0]?.productType?.feeCost * totalFeeQuantity?.reduce((c, a) => c + a, 0)
        totalFee = fee

        doc.font('Helvetica-Bold').fontSize(11).text(`${filteredPayments[0]?.product?.name}`, first, x)
        // x = x + 20

        doc.font('Helvetica-Bold').fontSize(11).text(`Total:`, fourth - 40, x)

        doc.font('Helvetica').fontSize(12).text(`${quantity}`, fourth + 20 - ((`${quantity}`)?.length * digitValue), x)
        doc.font('Helvetica').fontSize(12).text(`€`, fifth + 10, x)
        doc.font('Helvetica').fontSize(12).text(`${convertRoundNumber(total)}`, fifth + 70 - (convertRoundNumber(total)?.length * digitValue), x)

        doc.font('Helvetica').fontSize(12).text(`€`, sixth, x)

        doc.font('Helvetica').fontSize(12).text(`${convertRoundNumber(totalFee)}`, sixth + 55 - (convertRoundNumber(totalFee)?.length * digitValue), x)

        x = x + 20

        doc.font('Helvetica').fontSize(11).text(`21%  BTW`, fifth + 10, x)

        doc.font('Helvetica').fontSize(12).text(`€`, sixth, x)

        doc.font('Helvetica').fontSize(12).text(`${convertRoundNumber(totalFee * tax)}`, sixth + 55 - (convertRoundNumber(totalFee * tax)?.length * digitValue), x)

        x = x + 20
        let feeCostwithPercentage = (+convertRoundNumber(totalFee)) + (+convertRoundNumber(totalFee * tax))
        totalFeeCosts.push(feeCostwithPercentage)

        doc.font('Helvetica').fontSize(12).text(`€`, sixth, x)

        doc.font('Helvetica').fontSize(12).text(`${feeCostwithPercentage}`, sixth + 55 - (convertRoundNumber(feeCostwithPercentage)?.length * digitValue), x)

        x = x + 10
        doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________________', startX, x)

    }
    // x = x + 20
    console.log('x', x)
    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 20
    }
    let totalOfAmounts = totalAmounts?.reduce((acc, curr) => acc + curr, 0)
    let totalOfFeeCosts = totalFeeCosts?.reduce((acc, curr) => acc + curr, 0)

    doc.font('Helvetica-Bold').fontSize(11).text(`Total Amount:`, first, x)
    doc.font('Helvetica').fontSize(11).text(`€  ${convertRoundNumber(totalOfAmounts)}`, second, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`-      Total Fee Cost:`, third - 20, x)
    doc.font('Helvetica').fontSize(11).text(`€  ${convertRoundNumber(totalOfFeeCosts)}         =`, fourth - 20, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Payment:`, fifth, x)
    doc.font('Helvetica').fontSize(12).text(`€`, sixth, x)

    doc.font('Helvetica').fontSize(12).text(`${convertRoundNumber(totalOfAmounts - totalOfFeeCosts)}`, sixth + 55 - (convertRoundNumber(totalOfAmounts - totalOfFeeCosts)?.length * digitValue), x)

    if (x >= 700) {
        doc.addPage()
        x = 30
    } else {
        x = x + 10
    }
    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________________', startX, x)

    x = x + 20

    doc.font('Helvetica-Bold').text('2keepintouch.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)
    // doc.font('Helvetica-Bold').fontSize(11).text(`abc`, first, 780)

    x = x + 20

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)

        if (action === 'view') {
            return viewInvoice(blobUrl)
        }
        const a = document.createElement('a')


        a.href = blobUrl
        a.download = 'file.pdf'

        a.click()
    })
}

const createBuOrderReport = async (invoice, user, action, viewInvoice) => {
    // console.log('create Invoice Pdf function is working')
    // console.log('invoice', invoice)
    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    let iconUrl = 'https://res.cloudinary.com/dklfq58uq/image/upload/v1641853789/invoice-icon_czczkc.jpg'

    let imageBase64 = await toBase64(iconUrl)
    let orderDate = moment(invoice?.created).format('YYYY-MM-DD')

    doc.image(imageBase64, 410, 10, { width: 150, height: 130 })
    if (user?.businessName) {
        doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, 60, 100)
    }
    doc.text(user?.fullName, 60, 120)
    doc.text(user?.streetNumber, 60, 140)
    doc.text(user?.postalCodeCity, 60, 160)
    doc.text(user?.country, 60, 180)

    doc.fontSize(14).text(`Order Number:        ${invoice?.orderNumber}`, 60, 220)
    doc.fontSize(14).text(`Order Date:              ${dateFormater(orderDate)}`, 60, 240)

    doc.text(`Voorschoten`, 60, 260)

    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 270)

    let x = 300
    let rightVal = 186
    let digitVal = 7

    doc.font('Helvetica-Bold').fontSize(11).text(`Quantity: `, 60, x)
    let quantity = `${invoice?.quantity}`
    doc.font('Helvetica').fontSize(12).text(`${invoice?.quantity}`, rightVal - (quantity?.length * digitVal), x)
    x = x + 20

    // let productLength = `${invoice?.products?.length}`
    // doc.font('Helvetica-Bold').fontSize(11).text(`Products Related: `, 60, x)
    // doc.font('Helvetica').fontSize(12).text(`${productLength}`, rightVal - (productLength?.length * digitVal), x)
    // x = x + 20

    let productLength = `${invoice?.products?.length}`
    doc.font('Helvetica-Bold').fontSize(11).text(`Products Related: `, 60, x)
    doc.font('Helvetica').fontSize(12).text(`${productLength}`, rightVal - (productLength?.length * digitVal), x)

    if (invoice?.products?.length) {
        for (let product of invoice?.products) {
            x = x + 20

            doc.font('Helvetica').fontSize(11).text(`(•) ${product?.name} `, 70, x)
        }
    }

    x = x + 40

    // doc.text(`Wij zien uw betaling graag binnen 5 dagen tegemoet tnv 2keepintouch.com`, 60, x)

    x = x + 20

    doc.text('______________________________________________________________________________________', 60, x)

    x = x + 20

    doc.font('Helvetica-Bold').text('2keepintouch.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    x = x + 20

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)

        if (action === 'view') {
            return viewInvoice(blobUrl)
        }
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'orderReport.pdf'

        a.click()
    })
}
const createCouponReport = async (invoice, user, dateRange, adminCoupons) => {
    // console.log('create Invoice Pdf function is working')
    console.log('invoice', invoice)
    let buCouponsQuantity = invoice?.reduce((sum, { quantity }) => sum + quantity, 0)
    buCouponsQuantity = `${buCouponsQuantity}`
    let orderQuantity = `${invoice?.length}`
    let totalAmount = invoice?.reduce((sum, { invoice }) => sum + invoice?.totalAmount, 0)
    totalAmount = convertRoundNumber(totalAmount)
    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    let iconUrl = 'https://res.cloudinary.com/dklfq58uq/image/upload/v1641853789/invoice-icon_czczkc.jpg'

    let imageBase64 = await toBase64(iconUrl)

    doc.image(imageBase64, 410, 10, { width: 150, height: 130 })
    if (user?.businessName) {
        doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, 60, 100)
    }

    let startX = 120
    doc.text(user?.userName, 60, startX)

    if (user?.country) {
        startX += 20;
        let postalCode = user?.postalCode?.length > 1 ? `${user?.postalCode[0]?.postalcodeStart} - ${user?.postalCode[0]?.postalcodeEnd} , ${user?.postalCode[1]?.postalcodeStart} - ${user?.postalCode[1]?.postalcodeEnd}` : `${user?.postalCode[0]?.postalcodeStart} - ${user?.postalCode[0]?.postalcodeEnd}`
        doc.text(postalCode, 60, startX)
        startX += 20;
        doc.text(user?.country, 60, startX)
    }
    startX += 40
    doc.fontSize(14).text(`Coupon Report`, 60, 220)
    doc.fontSize(14).text(`Report Date Range:     ${dateFormater(dateRange?.date1)} - ${dateFormater(dateRange?.date2)}`, 60, 240)

    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 260)

    let x = 300
    let rightVal = 286
    let digitVal = 7

    doc.font('Helvetica-Bold').fontSize(11).text(`Total BU Orders Quantity: `, 60, x)
    doc.font('Helvetica').fontSize(12).text(`${orderQuantity}`, rightVal - (orderQuantity?.length * digitVal), x)
    x = x + 20
    doc.font('Helvetica-Bold').fontSize(11).text(`Total BU Coupons Quantity: `, 60, x)
    doc.font('Helvetica').fontSize(12).text(`${buCouponsQuantity}`, rightVal - (buCouponsQuantity?.length * digitVal), x)
    x = x + 20
    doc.font('Helvetica-Bold').fontSize(11).text(`Total Admin Coupons Quantity: `, 60, x)
    doc.font('Helvetica').fontSize(12).text(`${adminCoupons}`, rightVal - (adminCoupons?.toString()?.length * digitVal), x)
    x = x + 20
    doc.font('Helvetica-Bold').fontSize(11).text(`Total Amount: `, 60, x)
    doc.font('Helvetica').fontSize(12).text(`€ ${totalAmount}`, rightVal - 5 - (totalAmount?.length * digitVal), x)
    x = x + 20


    doc.text('______________________________________________________________________________________', 60, x)

    x = x + 20

    doc.font('Helvetica-Bold').text('2keepintouch.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    x = x + 20

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'couponReport.pdf'

        a.click()
    })
}

const createSubscriptionReport = async (invoice, user, dateRange, sharePercentage) => {
    console.log('invoice', invoice)
    let totalAmount = invoice?.reduce((sum, { amount }) => sum + amount, 0)
    totalAmount = convertRoundNumber(totalAmount)
    let totalQuantity = invoice?.reduce((sum, { quantity }) => sum + quantity, 0)
    totalQuantity = `${totalQuantity}`
    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    let iconUrl = 'https://res.cloudinary.com/dklfq58uq/image/upload/v1641853789/invoice-icon_czczkc.jpg'

    let imageBase64 = await toBase64(iconUrl)

    doc.image(imageBase64, 410, 10, { width: 150, height: 130 })
    if (user?.businessName) {
        doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, 60, 100)
    }

    let startX = 120
    doc.text(user?.userName, 60, startX)

    if (user?.country) {
        startX += 20;
        let postalCode = user?.postalCode?.length > 1 ? `${user?.postalCode[0]?.postalcodeStart} - ${user?.postalCode[0]?.postalcodeEnd} , ${user?.postalCode[1]?.postalcodeStart} - ${user?.postalCode[1]?.postalcodeEnd}` : `${user?.postalCode[0]?.postalcodeStart} - ${user?.postalCode[0]?.postalcodeEnd}`
        doc.text(postalCode, 60, startX)
        startX += 20;
        doc.text(user?.country, 60, startX)
        startX += 20;
        doc.text(`Share: ${sharePercentage} %`, 60, startX)
    }

    startX += 40
    doc.fontSize(14).text(`Subscription Report ${user?.country ? '' : 'All'}`, 60, 220)
    doc.fontSize(14).text(`Report Date Range:     ${dateFormater(dateRange?.date1)} - ${dateFormater(dateRange?.date2)}`, 60, 240)

    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 260)

    let x = 280
    let indVal = 80
    let subVal = 120
    let quantityVal = 350
    let amountVal = 450
    let digitVal = 7

    doc.font('Helvetica-Bold').fontSize(11).text(`#`, indVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Subscription Name`, subVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Quantity`, quantityVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Amount`, amountVal, x)
    x = x + 20
    let index = 1
    for (let inv of invoice) {

        let quantity = `${inv?.quantity}`
        let amount = convertRoundNumber(inv?.amount)

        doc.font('Helvetica').fontSize(11).text(`${index}`, indVal, x)
        doc.font('Helvetica').fontSize(11).text(`${inv?.subscriptionName}`, subVal, x)
        doc.font('Helvetica').fontSize(11).text(`${quantity}`, quantityVal + 20 - (quantity?.length * digitVal), x)
        doc.font('Helvetica').fontSize(11).text(`€ ${amount}`, amountVal, x)
        index += 1
        x = x + 20
    }

    x = x + 20
    doc.font('Helvetica-Bold').fontSize(11).text(`Total`, subVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`${totalQuantity}`, quantityVal + 20 - (totalQuantity?.length * digitVal), x)
    doc.font('Helvetica-Bold').fontSize(11).text(`€ ${totalAmount}`, amountVal, x)

    x = x + 20

    doc.text('______________________________________________________________________________', 60, x)

    x = x + 20

    doc.font('Helvetica-Bold').text('2keepintouch.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    x = x + 20

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'subscriptionReport.pdf'

        a.click()
    })
}
const createFeeReport = async (invoice, user, dateRange, sharePercentage) => {
    console.log('invoice', invoice)
    let totalAmount = invoice?.reduce((sum, { amount }) => sum + amount, 0)
    totalAmount = convertRoundNumber(totalAmount)
    let totalFee = invoice?.reduce((sum, { fee }) => sum + fee, 0)
    totalFee = convertRoundNumber(totalFee)
    let totalTransactions = invoice?.reduce((sum, { transactions }) => sum + transactions, 0)
    totalTransactions = `${totalTransactions}`
    let totalQuantity = invoice?.reduce((sum, { quantity }) => sum + quantity, 0)
    totalQuantity = `${totalQuantity}`
    const doc = new PDFDocument()

    const stream = doc.pipe(blobStream())

    let iconUrl = 'https://res.cloudinary.com/dklfq58uq/image/upload/v1641853789/invoice-icon_czczkc.jpg'

    let imageBase64 = await toBase64(iconUrl)

    doc.image(imageBase64, 410, 10, { width: 150, height: 130 })
    if (user?.businessName) {
        doc.font('Helvetica-Bold').fontSize(10).text(user?.businessName, 60, 100)
    }

    let startX = 120
    doc.text(user?.userName, 60, startX)

    if (user?.country) {
        startX += 20;
        let postalCode = user?.postalCode?.length > 1 ? `${user?.postalCode[0]?.postalcodeStart} - ${user?.postalCode[0]?.postalcodeEnd} , ${user?.postalCode[1]?.postalcodeStart} - ${user?.postalCode[1]?.postalcodeEnd}` : `${user?.postalCode[0]?.postalcodeStart} - ${user?.postalCode[0]?.postalcodeEnd}`
        doc.text(postalCode, 60, startX)
        startX += 20;
        doc.text(user?.country, 60, startX)
        startX += 20;
        doc.text(`Share: ${sharePercentage} %`, 60, startX)
    }

    startX += 40
    doc.fontSize(14).text(`Fees Report ${user?.country ? '' : 'All'}`, 60, 220)
    doc.fontSize(14).text(`Report Date Range:     ${dateFormater(dateRange?.date1)} - ${dateFormater(dateRange?.date2)}`, 60, 240)

    doc.font('Helvetica').fontSize(10).text('______________________________________________________________________________________', 60, 260)

    let x = 280
    let subVal = 120 - 60
    let transactionVal = 230 - 60
    let quantityVal = 320 - 60
    let feeCostVal = 340
    let amountVal = 430
    let feeVal = 480
    let digitVal = 7

    doc.font('Helvetica-Bold').fontSize(11).text(`Product Type`, subVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Transaction`, transactionVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Quantity`, quantityVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Fee Cost`, feeCostVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Amount`, amountVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`Fees`, feeVal, x)
    x = x + 20
    let index = 1
    for (let inv of invoice) {

        let transactions = `${inv?.transactions}`
        let quantity = `${inv?.quantity}`
        let amount = convertRoundNumber(inv?.amount)
        let fee = convertRoundNumber(inv?.fee)
        let feeCost = inv?.feeCost?.join('/')
        doc.font('Helvetica').fontSize(11).text(`${inv?.productType}`, subVal, x)
        doc.font('Helvetica').fontSize(11).text(`${transactions}`, transactionVal + 20 - (transactions?.length * digitVal), x)
        doc.font('Helvetica').fontSize(11).text(`${quantity}`, quantityVal + 20 - (quantity?.length * digitVal), x)
        doc.font('Helvetica').fontSize(11).text(`${feeCost}`, feeCostVal + 20, x)
        doc.font('Helvetica').fontSize(11).text(`€ ${amount}`, amountVal, x)
        doc.font('Helvetica').fontSize(11).text(`€ ${fee}`, feeVal, x)
        index += 1
        x = x + 20
    }

    x = x + 20
    doc.font('Helvetica-Bold').fontSize(11).text(`Total`, subVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`${totalTransactions}`, transactionVal + 20 - (totalTransactions?.length * digitVal), x)
    doc.font('Helvetica-Bold').fontSize(11).text(`${totalQuantity}`, quantityVal + 20 - (totalQuantity?.length * digitVal), x)
    doc.font('Helvetica-Bold').fontSize(11).text(`€ ${totalAmount}`, amountVal, x)
    doc.font('Helvetica-Bold').fontSize(11).text(`€ ${totalFee}`, feeVal, x)

    x = x + 20

    doc.text('______________________________________________________________________________', 60, x)

    x = x + 20

    doc.font('Helvetica-Bold').text('2keepintouch.com | Bureau Integra bv | Wijngaardenlaan 17 | 2252 XJ Voorschoten', 105, x)

    x = x + 20

    doc.text('ING NL37 INGB 0004 6739 78 | Kvknr: 28104652 | BTW: NL818216864B02', 130, x)

    doc.end()

    stream.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = blobUrl
        a.download = 'feeReport.pdf'

        a.click()
    })
}

const getAdminFaq = (adminActions) => {
    axios.get(ADMIN.GET_ADMIN_FAQ)
        .then((res) => {
            const { data } = res
            data?.howIsWorkArray?.length && data?.howIsWorkArray.sort((a, b) => a.priority - b.priority)
            if (Object.keys(data?.FaqArrayGrourp).length > 0) {
                const arr = Object.entries(data?.FaqArrayGrourp);
                arr.forEach(([key, value]) => {
                    value.sort((a, b) => a.priority - b.priority);
                });
                const sortedObj = Object.fromEntries(arr);
                adminActions?.addAdminFaq([data?.howIsWorkArray || [], sortedObj || {}])
            } else {
                adminActions?.addAdminFaq([data?.howIsWorkArray || [], data?.FaqArrayGrourp || {}])
            }
        }).catch((e) => {
            adminActions?.addAdminFaq([])
        })
}

const getFaq = (userActions) => {
    axios.get(ADMIN.GET_ADMIN_FAQ)
        .then((res) => {
            const { data } = res
            data?.howIsWorkArray?.length && data?.howIsWorkArray.sort((a, b) => a.priority - b.priority)
            if (Object.keys(data?.FaqArrayGrourp).length > 0) {
                const arr = Object.entries(data?.FaqArrayGrourp);
                arr.forEach(([key, value]) => {
                    value.sort((a, b) => a.priority - b.priority);
                });
                const sortedObj = Object.fromEntries(arr);
                userActions?.addUserFaq([data?.howIsWorkArray || [], sortedObj || {}])
            } else {
                userActions?.addUserFaq([data?.howIsWorkArray || [], data?.FaqArrayGrourp || {}])
            }
        }).catch((e) => {
            userActions?.addUserFaq([])
        })
}

const assignLanguage = (language) => {
    return language === 'english' ? EnglishLanguage :
        language === 'egypt' ? EgyptLanguage :
            NlLanguage;
}

const assignMessage = (messageKey, language) => {
    const messageObject = AllBackendMessages?.[messageKey];

    if (messageObject) {
        const languageKey = `message${language.charAt(0).toUpperCase() + language.slice(1)}`;
        return messageObject?.[languageKey] || 'Language not supported';
    } else {
        return messageKey;
    }
}

const toMinutes = (str) => str?.split(':')?.reduce((h, m) => h * 60 + +m)

const toString = (min) => (Math.floor(min / 60) + ':' + (min % 60))?.replace(/\b\d\b/, '0$&')
const checkTimeSlots = (timeRange, interval) => {
    let t1 = toMinutes(timeRange.time1)
    let t2 = toMinutes(timeRange.time2)
    let diff = t2 - t1
    console.log('diff', diff, (diff % interval))
    return (diff % interval) === 0
}

const checkSelectOption = (input, option) => option?.children?.toLowerCase().includes(input.toLowerCase().trim())

export {
    requiredMessage,
    inputPlace,
    setActiveMenu,
    successMessage,
    infoMessage,
    errorMessage,
    warningMessage,
    successNotification,
    errorNotification,
    facebookLogin,
    googleLogin,
    convertTitle,
    convertDate,
    setActiveAdminMenu,
    datePlace,
    convertNumber,
    convertFloat,
    createInvoicePdf,
    dateFormater,
    convertMessage,
    createPostalCard,
    splitDate,
    setHoursMinutes,
    createFrontSide,
    createBackSide,
    getBase64,
    disabledDate,
    absoluteDate,
    relativeDate,
    absoluteLocalDate,
    absoluteDateTz,
    absoluteLocalDateTz,
    relativeDateTz,
    stringLimiter,
    createLoyaltyCard,
    createLotteryCard,
    generateQR,
    createGiftCard,
    provisionCalculate,
    validateTime,
    errorMessageWithDuration,
    setKeyMenu,
    getAllUserData,
    getBuUserData,
    createBuInvoicePdf,
    calculateReviewAverage,
    createPaymentReportPdf,
    calculateAverageForStaff,
    calculateAverageForFacilities,
    calculateAverageForCleanliness,
    calculateAverageForComfort,
    calculateAverageForValueForMoney,
    calculateAverageForLocation,
    calculateAverageForFreeWifi,
    convertRoundNumber,
    createBuOrderReport,
    createCouponReport,
    createSubscriptionReport,
    createFeeReport,
    getAdminFaq,
    getFaq,
    allAdminData,
    generalData,
    toBase64,
    allBUserData,
    allUserData,
    assignLanguage,
    assignMessage,
    toString,
    toMinutes,
    checkTimeSlots,
    checkSelectOption
}