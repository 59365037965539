import { Button, Dropdown, Input, Menu, Modal, Typography } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { QrReader } from 'react-qr-reader'
import { POST } from '../../utils/apis'
import { constraints } from '../../utils/constants'
import { allBUserData, assignMessage, errorMessage, successMessage, validateTime } from '../../utils/helpers'
// import { Carousel } from 'react-responsive-carousel'

const { Title } = Typography
const AcceptVouchers = (props) => {
    const { user, userActions, language, selectedLanguage } = props
    const isEnglish = language?.language === 'english'
    const [visible, setVisible] = useState(false)
    const [visibleQR, setVisibleQR] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [lottery, setLottery] = useState('')
    const [QRCode, setQRCode] = useState(null)
    const [voucherDetail, setVoucherDetail] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false)

    useEffect(() => {
        allBUserData?.getAllVoucher(userActions, user?._id)
    }, [voucherDetail])
    useEffect(() => {
        if (QRCode && !loading) {
            console.log('QRCode', QRCode)
            handleOk(QRCode)
        }
    }, [QRCode])

    const menu = (
        <Menu>
            <Menu.Item onClick={() => setVisible(true)} key='1'>{selectedLanguage?.ClaimByCode}</Menu.Item>
            <Menu.Item onClick={() => setVisibleQR(true)} key='2'>{selectedLanguage?.ClaimByQRCode}</Menu.Item>
        </Menu>
    )

    const handleOk = (code = null) => {
        setConfirmLoading(true)
        setLoading(true)

        axios.post(POST.VERIFYVOUCHER, { voucherCode: lottery || code, userId: user?._id })
            .then((res) => {
                const { data } = res
                // console.log('data===>', data)
                if (data?.success) {
                    console.log(data?.data)
                    setVoucherDetail(data?.data)
                    handleCancel()
                    setVisible(false)
                    setQRCode(null)
                    setVisibleQR(false)
                    setConfirmLoading(false)
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            })
            .catch((e) => {
                console.log('e ', e)
                setVisible(false)
                setConfirmLoading(false)
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })

    }

    const handleCancel2 = () => {
        setVisible(false)
        setVisibleQR(false)
        setLottery('')

    }
    const handleCancel = () => {
        setVisible(false)
        setVisibleQR(false)
        showModal1()
        setLottery('')
    }

    const showModal1 = () => {
        setIsModalVisible(true)
    }

    const handlelotteryInput = (e) => {

        let value = e?.target?.value?.replace(/ /g, '')
        let event = e?.nativeEvent?.data

        if (event && ((value?.length === 3 || value?.length === 7) || (lottery?.length === 3 || lottery.length === 7))) {
            if (lottery?.length === 3 || lottery.length === 7) {
                value = `${lottery}-${event}`
            }
            else {
                value = `${value}-`
            }
        }

        setLottery(value)
    }


    const acceptCode = () => {
        setLoading(true)
        let obj = {
            voucherCode: voucherDetail?.voucherCode,
            userId: user?._id,
            _id: voucherDetail?._id,
            productType: voucherDetail?.product?.productType
        }
        axios.post(POST.ACCEPTVOUCHERS, obj)
            .then((res) => {
                const { data } = res
                // console.log('data===>', data)
                if (data?.success) {
                    successMessage(assignMessage(data?.message, language?.language))
                    setIsModalVisible(false)
                    setVoucherDetail({})
                }
                else {
                    errorMessage(assignMessage(data?.message, language?.language))
                }
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            })
            .catch((e) => {
                console.log('e ', e)
                setLoading(false)
                errorMessage(assignMessage(e?.message, language?.language))
            })
    }

    const scanQr = async qr => {
        let code = qr?.text

        if (code) {
            setQRCode(code)
        }
    }

    return (
        <div>
            <Dropdown overlay={menu} placement='bottomLeft'>
                <Button
                    style={{ height: '40px', width: '160px', marginTop: '0px' }}
                    className='addproduct-text'
                    type='primary'
                >
                    {selectedLanguage?.AcceptVouchers}
                </Button>
            </Dropdown>
            <Modal 
                open={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel2}
                className='pop-info-modal'
                style={{ top: '10px' }}
                footer={null}
            >
                <p className='new-p-question'>{selectedLanguage?.Vouchers}</p>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4>{selectedLanguage?.EnteryourVoucherCode}</h4>
                    <div className='form-input-single-input'>
                        <Input
                            className='form-input-new-style'
                            autoFocus
                            required
                            maxLength={11}
                            value={lottery}
                            onChange={handlelotteryInput}
                            placeholder='e.g: 1a2-7c6-ab6'
                        />
                    </div>
                </div>
                <Button
                    key='ok'
                    onClick={handleOk}
                    disabled={lottery?.length !== 11}
                    style={{ height: '40px', width: '100%', marginTop: '10px' }}
                    className='addproduct-text'
                    type='primary'
                    loading={loading}
                >
                    {selectedLanguage?.AcceptVoucher}
                </Button>
            </Modal>

            <Modal 
                open={visibleQR}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel2}
                footer={null }
                className='pop-info-modal'
                style={{ top: '10px' }}
            >
                <p className='new-p-question'>{selectedLanguage?.ActivateQR}</p>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4>{selectedLanguage?.ScanYourQR}</h4>
                    <QrReader
                        delay={1000}
                        onError={e => errorMessage(selectedLanguage?.WebCamNotFound)}
                        constraints={constraints}
                        // constraints={{facingMode: 'environment'}}
                        onResult={scanQr}
                        style={{ width: '100%' }}
                    />
                </div>
            </Modal>
            <Modal
                title={<center>
                    <Title level={4}>{selectedLanguage?.VoucherDetails}</Title>
                </center>}
                // visible={true}
                open={isModalVisible}
                // onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <>
                        <Button key='back' onClick={() => setIsModalVisible(false)}
                            type='primary'
                            style={{ height: '40px', width: '160px', marginTop: '10px' }}
                            className='addproduct-text'>
                            {selectedLanguage?.Cancel}
                        </Button>
                        <Button loading={loading} key='backs' onClick={acceptCode}
                            type='primary'
                            style={{ height: '40px', width: '160px', marginTop: '10px' }}
                            className='addproduct-text'>
                            {selectedLanguage?.AcceptVoucher}
                        </Button>
                    </>
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <ul>
                        <li><b>{selectedLanguage?.Amount}: </b>€ {voucherDetail?.invoice?.totalAmount ? voucherDetail?.invoice?.totalAmount : '-------'} </li>
                        <li><b>{selectedLanguage?.CreatedAt}: </b>{voucherDetail?.created ? moment(voucherDetail?.created)?.format('YYYY-MM-DD') : '-------'} </li>
                        <li><b>{selectedLanguage?.Code}: </b>{voucherDetail?.voucherCode ? voucherDetail?.voucherCode : '-------'}</li>
                        <li><b>{selectedLanguage?.ReservedDate}: </b>{voucherDetail?.voucherDate ? moment(voucherDetail?.voucherDate)?.format('YYYY-MM-DD') : '-------'}</li>
                        <li><b>{selectedLanguage?.ReservedTime}: </b>{voucherDetail?.voucherTime ? validateTime(voucherDetail?.voucherTime) : '-------'}</li>
                        <li><b>{selectedLanguage?.GuestName}: </b>{voucherDetail?.userId?.fullName ? voucherDetail?.userId?.fullName : '-------'}</li>
                        <li><b>{selectedLanguage?.GuestTelNo}: </b>{voucherDetail?.userId?.telNo ? voucherDetail?.userId?.telNo : '-------'}</li>
                    </ul>
                </div>
            </Modal>
        </div >
    )
}

export default AcceptVouchers